import React from "react";

const AppHeader = ({ pageNo }) => {
  let step = null;

  if (pageNo > 0 && pageNo < 7) step = 1;
  else if (pageNo > 6 && pageNo < 9) step = 2;
  else if (pageNo > 8) step = 3;
  else step = 0;
  return (
    <>
      <div className="driver-record-head">Driver Record</div>
      {pageNo < 11 ? (
        <div className="navigation">
          <ul className="nav-component text-center">
            <li className={step === 1 ? "active" : ""} id="step1">
              <a href="#">
                <span>1</span>Driver Information
              </a>
            </li>
            <li id="step2" className={step === 2 ? "active" : ""}>
              <a href="#">
                <span>2</span>Employment History
              </a>
            </li>
            <li id="step3" className={step === 3 ? "active" : ""}>
              <a href="#">
                <span>3</span>Other Information
              </a>
            </li>
          </ul>
        </div>
      ) : (
        <div style={{ height: "50px" }}></div>
      )}
    </>
  );
};

export default AppHeader;
