import React from "react";

const DriverAddressPanel = ({ list, editData, deleteData }) => {
  return (
    <div id="license-panel">
      {list.length > 0 ? (
        list.map((data, index) => (
          <div
            id={"panel" + data.id}
            key={index}
            className="licence-list address-panel-list"
          >
            <ul className="data">
              <li className="clearfix">
                <div className="address">
                  <b>{data.d_current_address}</b>
                  <br />
                  {data.d_current_city}
                  <br />
                  {data.d_current_province}, {data.d_current_zip}
                  <br />
                  {data.d_current_country}
                  <br />
                  <label>Number of years:&nbsp; </label>
                  {data.d_current_years}
                </div>
              </li>
              <li className="clearfix edit-data">
                <button
                  style={{ border: "none" }}
                  className="edit address-edit"
                  onClick={() => editData(index)}
                >
                  Edit
                </button>
                <button
                  style={{
                    border: "none",
                    float: "right",
                    color: "red",
                  }}
                  className="edit delete address-delete"
                  onClick={() => deleteData(index)}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <img src="images/empty-data.png" />
      )}
    </div>
  );
};

export default DriverAddressPanel;
