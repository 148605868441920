import axios from "axios";
import Swal from "sweetalert2";
const domain = "https://dotdrugtestings.com";  // production domain
// const domain = "https://unifyitconsulting.com"; // staging domain
const baseUri = domain + "/tabapi";
const postToServer = async (path, data) => {
  console.log("server hit => " + baseUri + path);
  try {
    const loader = document.getElementById("full-page-loader");
    loader.style.display = "flex";
    const res = await axios.post(baseUri + path, data, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
        "Access-Control-Request-Private-Network": "true",
      },
    });
    loader.style.display = "none";
    // console.log(res.data);
    if (res.data.status) {
      // Swal.fire({
      //   position: "top-end",
      //   icon: "success",
      //   title: res.data.data.msg,
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    } else {
      Swal.fire({
        title: res.data.data.msg,
        icon: "error",
      });
    }
    return { status: res.data.status, data: res.data.data };
  } catch (error) {
    Swal.fire({
      title: error.response.data.data.msg,
      icon: "error",
    });
    return { status: false, data: {} };
  }
};
const licenseDetailApi = async (data) => {
  const url = domain + "/tablet/tabmvr/webmvrapi";
  console.log("server hit => " + url);
  try {
    const res = await axios.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        // Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Private-Network": "true",
      },
    });
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
const getCompanies = async () => {
  const url = domain + "/tablet/Tabmvr/getAllCompaniesjson";
  console.log("server hit => " + url);
  try {
    const res = await axios.get(url, {
      headers: {
        // "Content-Type": "multipart/form-data",
        // Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Private-Network": "true",
      },
    });
    return res.data;
  } catch (error) {
    return [];
  }
};

// swal({
//   position: "top-end",
//   title: res.data.data.msg,
//   icon: "success",
//   timer: 1500,
// });

const finalSubmitToServer = async (data) => {
  const path = "/finalSubmit";
  console.log("server hit => " + baseUri + path);
  try {
    const loader = document.getElementById("full-page-loader");
    loader.style.display = "flex";
    const res = await axios.post(baseUri + path, data, {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + token,
        "Access-Control-Request-Private-Network": "true",
      },
    });
    loader.style.display = "none";
    // console.log(res);
    return { status: res.data.status, msg: res.data.data.msg };
  } catch (error) {
    return { status: false, msg: "Something went wrong" };
  }
};

export { postToServer, licenseDetailApi, getCompanies, finalSubmitToServer };

// mobiscroll config react
