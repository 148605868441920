import React from "react";

const DriverAccidentPanel = ({ list, editData, deleteData }) => {
  return (
    <div id="license-panel">
      {list.length > 0 ? (
        list.map((data, index) => (
          <div
            id={"panel" + data.id}
            key={index}
            className="licence-list accident-panel-list"
          >
            <ul className="data">
              <li className="clearfix">
                <label>Nature of Accident</label>
                <span className="value lic-no">{data.d_accident_nature}</span>
              </li>
              <li className="clearfix">
                <div className="pull-left w-50">
                  <label>Date</label>
                  <span className="value">{data.d_accident_todate}</span>
                </div>
                <div className="pull-right w-50 text-right">
                  <label>Injury</label>
                  <span className="value">{data.d_accident_injury}</span>
                </div>
              </li>
              <li className="clearfix">
                <label>Fatality</label>
                <span className="value">{data.d_accident_fatality}</span>
              </li>

              <li className="clearfix edit-data">
                <button
                  style={{ border: "none" }}
                  className="edit accident-edit"
                  onClick={() => editData(index)}
                >
                  Edit
                </button>
                {data.change_allowed ? (
                  <button
                    style={{
                      border: "none",
                      float: "right",
                      color: "red",
                    }}
                    className="edit delete accident-delete"
                    onClick={() => deleteData(index)}
                  >
                    Delete
                  </button>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ))
      ) : (
        <img src="images/empty-data.png" />
      )}
    </div>
  );
};

export default DriverAccidentPanel;
