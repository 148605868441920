import React from "react";

const EmploymentHistoryPanel = ({ list, editData, deleteData }) => {
  return (
    <div id="license-panel">
      {list.length > 0 ? (
        list.map((data, index) => (
          <div
            id={"panel" + data.id}
            key={index}
            className="licence-list employment-panel-list"
          >
            <ul className="data">
              <li className="clearfix">
                <label>Company Name</label>
                <span className="value lic-no">{data.eh_company_name}</span>
              </li>
              <li className="clearfix">
                <div className="pull-left w-50">
                  <label>City</label>{" "}
                  <span className="value">{data.eh_city}</span>
                </div>
                <div className="pull-right w-50 text-right">
                  <label>State/Province</label>
                  <span className="value">{data.eh_state}</span>
                </div>
              </li>
              <li className="clearfix">
                <label>Contact Person</label>
                <span className="value">{data.eh_company_cperson}</span>
              </li>
              <li className="clearfix edit-data">
                <button
                  style={{ border: "none" }}
                  className="edit employment-edit"
                  onClick={() => editData(index)}
                >
                  Edit
                </button>
                <button
                  style={{
                    border: "none",
                    float: "right",
                    color: "red",
                  }}
                  className="edit delete employment-delete"
                  onClick={() => deleteData(index)}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <img src="images/empty-data.png" />
      )}
    </div>
  );
};

export default EmploymentHistoryPanel;
