import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import SignaturePad from "signature_pad";
import "jquery-validation";
import $ from "jquery";

const HomePage = ({
  nextPage,
  signedImage,
  setSignedImage,
  termConAccepted,
  setTermConAccepted,
  lastPage,
}) => {
  // ---------- states variables ----------
  const [termConRead, setTermConRead] = useState(false);
  // const navigate = useNavigate();
  const onScroll = (e) => {
    if (!termConRead) {
      if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight)
        setTermConRead(true);
    }
  };
  // ---------- code runs onces when file loads ----------
  // useEffect(() => {
  //   // Signature Pad Code Start
  //   var canvas = document.getElementById("signature-pad");
  //   // Adjust canvas coordinate space taking into account pixel ratio,
  //   // to make it look crisp on mobile devices.
  //   // This also causes canvas to be cleared.

  //   var signaturePad = new SignaturePad(canvas, {
  //     backgroundColor: "rgba(0,0,0,0)", // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
  //     penColor: "rgb(0, 0, 0)",
  //   });

  //   function resizeCanvas() {
  //     // When zoomed out to less than 100%, for some very strange reason,
  //     // some browsers report devicePixelRatio as less than 1
  //     // and only part of the canvas is cleared then.
  //     var ratio = Math.max(window.devicePixelRatio || 1, 1);
  //     canvas.width = canvas.offsetWidth * ratio;
  //     canvas.height = canvas.offsetHeight * ratio;
  //     canvas.getContext("2d").scale(ratio, ratio);
  //     signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  //   }

  //   $("#clear_sign").click(function (e) {
  //     signaturePad.clear();
  //     $("#sign_image").attr("src", "");
  //     $("#sign_image").hide();
  //     $("#signature_input").val("");
  //   });
  //   $("#save_sign").click(function (e) {
  //     if (signaturePad.isEmpty()) {
  //       Swal.fire({
  //         icon: "info",
  //         title: "Please Sign First!",
  //       });
  //       return;
  //     }
  //     const imgData = signaturePad.toDataURL("image/png", 1.0);
  //     // console.log(imgData);
  //     $("#sign_image").attr("src", imgData);
  //     $("#signature_input").val(imgData);
  //     setSignedImage(imgData);
  //     // $("#sign_image").show();
  //     Swal.fire({
  //       position: "top-end",
  //       icon: "success",
  //       title: "Signature has been saved",
  //       showConfirmButton: false,
  //       timer: 1500,
  //     });
  //   });

  //   // window.addEventListener("resize", resizeCanvas);

  //   window.onresize = resizeCanvas;
  //   resizeCanvas();
  //   // if (currentTab == 9) {
  //   //     resizeCanvas();
  //   // }
  //   if (signedImage && signedImage !== "") {
  //     signaturePad.fromDataURL(signedImage);
  //   }
  // }, []);
  const goNext = async () => {
    // const imageData = $("#sign_image").attr("src");
    // if (imageData != "") {
    // if (signedImage != "") {
    //   // setSignedImage(imageData);
    //   nextPage();
    // } else {
    //   Swal.fire({
    //     icon: "info",
    //     title: "Please Save the Signature!",
    //   });
    // }
    nextPage();
  };
  // ---------- rendering UI ----------
  return (
    <>
      <div className="general-content term-cond">
        <div className="row d-flex">
          <div className="col-md-12">
            <div className="driver-form-container">
              <div className="container container-collapsed">
                <div className="form-wrap">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <h2 className="h2">TERMS AND CONDITIONS</h2>

                      <div className="tc-scroll-panel" onScroll={onScroll}>
                        <p>
                          By continuing with this application, I am agreeing to
                          all the terms mentioned below and confirm that all the
                          information in this application is correct. I
                          understand that, under the penalty of perjury,
                          knowingly making a false statement or
                          misrepresentation on the following form is a criminal
                          violation and I will be held solely responsible. I
                          acknowledge that the motor carrier I am applying for
                          and JST Drug Testing INC will not be held accountable
                          for any false or misleading information made on this
                          application.
                        </p>
                        <p>
                          <b>MOTOR VEHICLE RECORD OF VIOLATIONS</b> - I consent
                          to the release of my driving record (MVR) for review
                          by JST Drug Testing INC and the motor carrier I am
                          applying for with this documentation. I understand
                          that my driving record may be periodically obtained
                          and reviewed by said entities for the purpose of
                          initial and continued employment. I certify that I
                          have provided a full list of traffic violations for
                          which I have been convicted or forfeited bond or
                          collateral during the past 12 months (required by 49
                          CFR Section 391.27). If there are no violations
                          listed, I certify that I have not been convicted or
                          forfeited bond or collateral on account of any
                          violation required to be listed during the past 12
                          months. I understand that no person who operates a
                          commercial motor vehicle shall have more than one
                          driver’s license at any time as per 49 CFR 383.21. I
                          certify that I do not have more than one motor vehicle
                          license.
                        </p>
                        <p>
                          <b>STATEMENT OF ON-DUTY HOURS</b> - When motor
                          carriers are utilizing a driver for the first time,
                          they shall obtain from said driver a signed statement
                          with the total time on-duty during the last 7 days,
                          along with the date and time the driver was last
                          relieved from duty prior to being employed by said
                          motor carrier. Section 395.8 (J)(2) of the FMSCR.
                          Hours for any compensated work during the preceding 7
                          days, including work for a non-motor carrier entity,
                          must also be recorded. When employed by a motor
                          carrier, you must report on-duty time, including time
                          working for other employers, to said carrier. The
                          definition of on-duty time that can be found in
                          section 395.2 (8)(9) of the FMCSR, includes time
                          performing any work in the capacity, employment, or
                          service of a common contract or private motor carrier.
                          This includes any compensated work for any non-motor
                          carrier entities. The company must also be informed if
                          beginning any compensated work for any additional
                          employers from a non-motor carrier entity.
                        </p>
                        <p>
                          <b>DRUG AND ALCOHOL POLICY</b> – I attest that I have
                          been provided with all the educational materials
                          required by Part 382.601. I understand that I must
                          comply with my employers’ policies and procedures as
                          outlined by the requirements found in Part 382. All
                          materials are covered and have been discussed in
                          detail.
                        </p>
                        <p>
                          <b>PREVIOUS PRE-EMPLOYMENT INFORMATION</b> - I hereby
                          authorize JST Drug Testing INC the release of
                          information from my Department of Transportation
                          regulated Drug & Alcohol testing records in accordance
                          with DOT regulation and my safety performance history
                          information to my prospective employer for
                          investigation purposes as required by 49 CFR
                          40.331,382.413,391.23 and other applicable
                          requirements for 3 years preceding this release. I
                          authorize the release of information from my
                          Department of Transportation regulated drug and
                          alcohol testing records by my previous employers,
                          listed in Section I-B, to the employer listed in
                          Section I-A. This release is in accordance with the
                          DOT Regulation 49 CFR Part 40, Section 40.25. I
                          understand that the information to be released in
                          Section II-A by my previous employer is limited to the
                          following DOT-regulated testing items: I) Alcohol
                          tests with a result of 0.04 or higher; II) Verified
                          positive drug tests; III) Refusals to be tested; IIII)
                          Other violations of any DOT agency drug and alcohol
                          testing regulations; V) Information obtained from
                          previous employers of a drug and alcohol rule
                          violation; VI) Documentation, if applicable, of
                          completion of the Return-to-Duty process following a
                          rule violation.
                        </p>
                        <p>
                          This application was completed by me, and all entries
                          on it and information contained within is true and
                          correct to the best of my knowledge. I have been
                          advised of and read the following: I) In the event of
                          employment, false or misleading information given in
                          my application or interview may result in discharge;
                          II) I am required to abide by all rules & regulations
                          of the company; III) The information I provide
                          regarding current and/or previous employers may be
                          used, and those employers will be contacted, for the
                          purpose of investigating my safety performance history
                          as required by Title 49 CFR 391.23 (D); IIII) I have
                          the right to review information provided by previous
                          employers, have errors corrected by previous employers
                          and for those previous employers to resend the
                          corrected information to the prospective employer, and
                          have a rebuttal statement attached to the alleged
                          erroneous information. If the previous employer and I
                          cannot agree on the accuracy of the information,
                          additional information on this subject can be found in
                          Title 49 CFR 391.23(H) through 391.23 (L).
                        </p>
                      </div>
                      {termConRead ? (
                        <div className="form-check" id="terms-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="condCheck"
                            checked={termConAccepted}
                            onChange={(e) =>
                              setTermConAccepted(e.target.checked)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="condCheck"
                          >
                            I agree to the terms and conditions
                          </label>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{
                      visibility: termConAccepted ? "visible" : "hidden",
                    }}
                  >
                    {/* <div className="col-12 signature-panel text-center">
                      <label htmlFor="signature-pad">Signature</label>
                      <div className="sign-wrapper">
                        <canvas
                          id="signature-pad"
                          className="signature-pad"
                          width="200"
                          height="100"
                        ></canvas>
                      </div>
                      <input
                        type="hidden"
                        id="signature_input"
                        name="signature"
                        value=""
                        required
                      />
                      <img
                        src=""
                        id="sign_image"
                        style={{ display: "none" }}
                        width="200"
                      />
                      <div className="signature-control">
                        <button
                          type="button"
                          id="clear_sign"
                          className="btn btn-sm btn-warning"
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          id="save_sign"
                          className="btn btn-sm btn-success"
                        >
                          Save
                        </button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer for Driver Info Tab only  */}
      <div className="driver-footer">
        <div className="container">
          {termConAccepted ? (
            <button className="pull-right btn btn-dark next" onClick={goNext}>
              Next
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default HomePage;
