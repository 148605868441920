import React from "react";

const ThanksPage = () => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="driver-form-container" style={{ paddingTop: "50px" }}>
          <div className="container container-collapsed">
            <div className="row">
              <div className="col-md-12">
                <div className="thanks-txt text-center">
                  <img
                    src="images/circle_correct_mark_success_tick_icon.png"
                    style={{ maxWidth: "90px", margin: "30px 0px" }}
                  />
                  <h1 className="h1">Thank You!</h1>
                  <h4 className="h4">
                    Your application was submitted successfully.
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="driver-footer w-100 text-center ">
            <div className="container">
              <button
                className="btn btn-dark"
                onClick={() => window.location.reload()}
              >
                Add New Driver
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
