import React, { useEffect } from "react";
// import CusDatePicker from "../components/CusDatePicker";
import CustomDatePicker from "../components/CustomDatePicker";

const DriverLicenceForm = ({ data, eventHandler, setData }) => {
  return (
    <div className="licenseForms" id="licenseForm">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>License Number</label>
            <input
              className="form-control"
              type="text"
              name="d_license_number"
              value={data.d_license_number}
              onChange={eventHandler}
              required
              maxLength="50"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>License Expire date</label>
            {/* <input
              className="form-control dynamicdate jqdate"
              type="text"
              name="d_license_exp_date"
              value={data.d_license_exp_date}
              onChange={eventHandler}
              maxLength="40"
            /> */}
            {/* <CusDatePicker
              name="d_license_exp_date"
              data={data}
              setData={setData}
            /> */}
            <CustomDatePicker
              name="d_license_exp_date"
              data={data}
              setData={setData}
              // required={true}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>License Type</label>
            <select
              className="form-control"
              name="d_license_type"
              value={data.d_license_type}
              onChange={eventHandler}
              required
            >
              <option value="">Select License type</option>
              <option value="Class-A">Class-A </option>
              <option value="Class-C">Class-C </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Issue Authority</label>
            <select
              className="form-control"
              name="d_state_issue"
              value={data.d_state_issue}
              onChange={eventHandler}
              aria-labelledby="label_136"
              required
            >
              <option value="">Select Issue Authority</option>
              <option value="Alabama"> Alabama </option>
              <option value="Alaska"> Alaska </option>
              <option value="Arizona"> Arizona </option>
              <option value="Arkansas"> Arkansas </option>
              <option value="California"> California </option>
              <option value="Colorado"> Colorado </option>
              <option value="Connecticut"> Connecticut </option>
              <option value="Delaware"> Delaware </option>
              <option value="District of Columbia">District of Columbia</option>
              <option value="Florida"> Florida </option>
              <option value="Georgia"> Georgia </option>
              <option value="Hawaii"> Hawaii </option>
              <option value="Idaho"> Idaho </option>
              <option value="Illinois"> Illinois </option>
              <option value="Indiana"> Indiana </option>
              <option value="Iowa"> Iowa </option>
              <option value="Kansas"> Kansas </option>
              <option value="Kentucky"> Kentucky </option>
              <option value="Louisiana"> Louisiana </option>
              <option value="Maine"> Maine </option>
              <option value="Maryland"> Maryland </option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan"> Michigan </option>
              <option value="Minnesota"> Minnesota </option>
              <option value="Mississippi"> Mississippi </option>
              <option value="Missouri"> Missouri </option>
              <option value="Montana"> Montana </option>
              <option value="Nebraska"> Nebraska </option>
              <option value="Nevada"> Nevada </option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York"> New York </option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Ohio"> Ohio </option>
              <option value="Oklahoma"> Oklahoma </option>
              <option value="Oregon"> Oregon </option>
              <option value="Pennsylvania"> Pennsylvania </option>
              <option value="Puerto Rico"> Puerto Rico </option>
              <option value="Rhode Island"> Rhode Island </option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee"> Tennessee </option>
              <option value="Texas"> Texas </option>
              <option value="Utah"> Utah </option>
              <option value="Vermont"> Vermont </option>
              <option value="Virgin Islands">Virgin Islands</option>
              <option value="Virginia"> Virginia </option>
              <option value="Washington"> Washington </option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin"> Wisconsin </option>
              <option value="Wyoming"> Wyoming </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverLicenceForm;
