import React from "react";

const DriverLicencePanel = ({ list, editData, deleteData }) => {
  return (
    <div id="license-panel">
      {list.length > 0 ? (
        list.map((data, index) => (
          <div
            id={"panel" + data.id}
            key={index}
            className="licence-list license-panel-list"
          >
            <ul className="data">
              <li className="clearfix">
                <label>License Number</label>
                <span className="value lic-no">{data.d_license_number}</span>
              </li>
              <li className="clearfix">
                <div className="pull-left w-50">
                  <label>Expires</label>
                  <span className="value">{data.d_license_exp_date}</span>
                </div>
                <div className="pull-right w-50 text-right">
                  <label>Type</label>
                  <span className="value">{data.d_license_type}</span>
                </div>
              </li>
              <li className="clearfix">
                <label>Issuing Authority</label>
                <span className="value">{data.d_state_issue}</span>
              </li>
              <li className="clearfix edit-data">
                <button
                  style={{ border: "none" }}
                  className="edit license-edit"
                  onClick={() => editData(index)}
                >
                  Edit
                </button>
                <button
                  type="button"
                  style={{
                    border: "none",
                    float: "right",
                    color: "red",
                  }}
                  className="edit delete license-delete"
                  onClick={() => deleteData(index)}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        ))
      ) : (
        <img src="images/empty-data.png" />
      )}
    </div>
  );
};

export default DriverLicencePanel;
