import React, { useEffect } from "react";
import { dateToLongStr } from "../common";
import Swal from "sweetalert2";
import { finalSubmitToServer } from "../apis";

const ReviewDetails = ({
  basicInfos,
  setPageNo,
  setIsDone,
  licenses,
  addresses,
  violations,
  accidents,
  experiences,
  employmentHistories,
  preEmployment,
  otherInformation,
  credentials,
}) => {
  const finalSubmit = () => {
    console.log("file submited");
    Swal.fire({
      title: "Please enter password to submit",
      html: `<input type="password" id="sub_pass" class="swal2-input" placeholder="Password">`,
      confirmButtonText: "Submit",
      preConfirm: () => {
        const password = Swal.getPopup().querySelector("#sub_pass").value;
        if (!password) {
          Swal.showValidationMessage(`Please enter password`);
        }
        return { password: password };
      },
    }).then(async (result) => {
      if (result.value.password === "123456") {
        const result = await finalSubmitToServer({
          ...credentials,
        });
        // console.log(result);
        if (result.status) {
          setPageNo(11);
        } else Swal.fire(result.msg);
      } else {
        Swal.fire("Incorrect Password");
      }
    });
  };
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    setIsDone(true);
  }, []);
  return (
    <>
      <div className="general-content driver-info review-details">
        <div className="row d-flex">
          <div className="col-md-12">
            <div className="driver-form-container">
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Basic Informations</h3>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(1)}
                    >
                      Edit
                    </button>
                  </div>
                  <br />
                  <div className="col-md-8 pl-md-0">
                    <div className="licence-list license-panel-list">
                      <ul className="data">
                        <li className="clearfix">
                          <label>Company Name</label>
                          <p>{basicInfos.tk_company_uid}</p>
                        </li>
                        <li className="clearfix">
                          <div className="pull-left w-50">
                            <label>Name</label>
                            <p>
                              {basicInfos.d_first_name}{" "}
                              {basicInfos.d_middle_name}{" "}
                              {basicInfos.d_last_name}
                            </p>
                          </div>
                          <div className="pull-right w-50 text-right">
                            <label>Date of Birth</label>
                            <p>{basicInfos.d_date_of_birth}</p>
                          </div>
                        </li>
                        <li className="clearfix">
                          <div className="pull-left w-50">
                            <label>E-mail</label>
                            <p>{basicInfos.d_email}</p>
                          </div>
                          <div className="pull-right w-50 text-right">
                            <label>Phone Number</label>
                            <p>{basicInfos.d_phone_number}</p>
                          </div>
                        </li>
                        <li className="clearfix">
                          <label>Social Security Number</label>
                          <p>{basicInfos.d_social_s_no}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Licences</h3>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(2)}
                    >
                      Edit
                    </button>
                  </div>
                  <br />
                  <div id="license-panel">
                    {licenses.map((data, index) => (
                      <div className="col-md-4 pl-md-0">
                        <div
                          id={"panel" + data.id}
                          key={index}
                          className="licence-list license-panel-list"
                        >
                          <ul className="data">
                            <li className="clearfix">
                              <label>License Number</label>
                              <span className="value lic-no">
                                {data.d_license_number}
                              </span>
                            </li>
                            <li className="clearfix">
                              <div className="pull-left w-50">
                                <label>Expires</label>
                                <span className="value">
                                  {data.d_license_exp_date}
                                </span>
                              </div>
                              <div className="pull-right w-50 text-right">
                                <label>Type</label>
                                <span className="value">
                                  {data.d_license_type}
                                </span>
                              </div>
                            </li>
                            <li className="clearfix">
                              <label>Issuing Authority</label>
                              <span className="value">
                                {data.d_state_issue}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Addresses</h3>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(3)}
                    >
                      Edit
                    </button>
                  </div>
                  <br />
                  <div id="license-panel">
                    {addresses.map((data, index) => (
                      <div className="col-md-4 pl-md-0">
                        <div
                          id={"panel" + data.id}
                          key={index}
                          className="licence-list address-panel-list"
                        >
                          <ul className="data">
                            <li className="clearfix">
                              <div className="address">
                                <b>{data.d_current_address}</b>
                                <br />
                                {data.d_current_city}
                                <br />
                                {data.d_current_province}, {data.d_current_zip}
                                <br />
                                {data.d_current_country}
                                <br />
                                <label>Number of years:&nbsp; </label>
                                {data.d_current_years}
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Violations</h3>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(4)}
                    >
                      Edit
                    </button>
                  </div>
                  <br />
                  <div id="license-panel">
                    {violations.map((data, index) => (
                      <div className="col-md-4 pl-md-0">
                        <div
                          id={"panel" + data.id}
                          key={index}
                          className="licence-list violation-panel-list"
                        >
                          <ul className="data">
                            <li className="clearfix">
                              <label>Charge</label>
                              <span className="value lic-no">
                                {data.d_trfic_charge}
                              </span>
                            </li>
                            <li className="clearfix">
                              <div className="pull-left w-50">
                                <label>Date</label>
                                <span className="value">
                                  {data.d_trfic_todate}
                                </span>
                              </div>
                              <div className="pull-right w-50 text-right">
                                <label>Penalty</label>
                                <span className="value">
                                  {data.d_trfic_penalty}
                                </span>
                              </div>
                            </li>
                            <li className="clearfix">
                              <label>Location</label>
                              <span className="value">
                                {data.d_trfic_location}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Accidents</h3>
                    {/* &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(5)}
                    >
                      Edit
                    </button> */}
                  </div>
                  <br />
                  <div id="license-panel">
                    {accidents.map((data, index) => (
                      <div className="col-md-4 pl-md-0">
                        <div
                          id={"panel" + data.id}
                          key={index}
                          className="licence-list accident-panel-list"
                        >
                          <ul className="data">
                            <li className="clearfix">
                              <label>Nature of Accident</label>
                              <span className="value lic-no">
                                {data.d_accident_nature}
                              </span>
                            </li>
                            <li className="clearfix">
                              <div className="pull-left w-50">
                                <label>Date</label>
                                <span className="value">
                                  {data.d_accident_todate}
                                </span>
                              </div>
                              <div className="pull-right w-50 text-right">
                                <label>Injury</label>
                                <span className="value">
                                  {data.d_accident_injury}
                                </span>
                              </div>
                            </li>
                            <li className="clearfix">
                              <label>Fatality</label>
                              <span className="value">
                                {data.d_accident_fatality}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Experiences</h3>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(6)}
                    >
                      Edit
                    </button>
                  </div>
                  <br />
                  <div id="license-panel">
                    {experiences.map((data, index) => (
                      <div className="col-md-4 pl-md-0">
                        <div
                          id={"panel" + data.id}
                          key={index}
                          className="licence-list experience experience-panel-list"
                        >
                          <ul className="data ">
                            <li className="clearfix">
                              <div className="address">
                                <b>
                                  {data.d_class_equipment}
                                  <label>&nbsp;with&nbsp; </label>
                                  {data.d_equipment_name}
                                </b>
                                <br />
                                <label>Entry Level Training: &nbsp; </label>
                                <b>{data.d_class_elt}</b>
                                <br />
                                <b>
                                  {data.d_from}- {data.d_to}
                                </b>
                                <br />
                                <label>Appx. Miles: &nbsp;</label>
                                <b>{data.approx}</b>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="form-wrap">
                  <div className="row">
                    <h3 style={{ display: "inline" }}>Employment Histories</h3>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      className="btn btn-primary"
                      onClick={() => setPageNo(7)}
                    >
                      Edit
                    </button>
                  </div>
                  <br />
                  <div id="license-panel">
                    {employmentHistories.map((data, index) => (
                      <div className="col-md-4 pl-md-0">
                        <div
                          id={"panel" + data.id}
                          key={index}
                          className="licence-list employment-panel-list"
                        >
                          <ul className="data">
                            <li className="clearfix">
                              <label>Company Name</label>
                              <span className="value lic-no">
                                {data.eh_company_name}
                              </span>
                            </li>
                            <li className="clearfix">
                              <div className="pull-left w-50">
                                <label>City</label>{" "}
                                <span className="value">{data.eh_city}</span>
                              </div>
                              <div className="pull-right w-50 text-right">
                                <label>State/Province</label>
                                <span className="value">{data.eh_state}</span>
                              </div>
                            </li>
                            <li className="clearfix">
                              <label>Contact Person</label>
                              <span className="value">
                                {data.eh_company_cperson}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              <hr />
              <br />
              <div className="container">
                <div className="row">
                  <h3 style={{ display: "inline" }}>Pre Employment</h3>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary"
                    onClick={() => setPageNo(8)}
                  >
                    Edit
                  </button>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tested Positive For Substance Abuse?</label>
                      <p>{preEmployment.testdpostvsubabs}</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Refused A Substance Abuse Test?</label>
                      <p>{preEmployment.refusedsubtabtest}</p>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Tested Positive For Alcohol Misuse?</label>
                      <p>{preEmployment.testdposvealchmisue}</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Refused An Alcohal Misuse Test?</label>
                      <p>{preEmployment.refanalohlmisutest}</p>
                    </div>
                  </div>
                </div>
                {preEmployment.testdpostvsubabs === "Yes" ||
                preEmployment.refusedsubtabtest === "Yes" ||
                preEmployment.testdposvealchmisue === "Yes" ||
                preEmployment.refanalohlmisutest === "Yes" ? (
                  <div className="row" id="sapRow">
                    <div className="col-md-12">
                      <div className="sec-header"></div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>SAP Name</label>
                        <p>{preEmployment.emphistestdname}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>SAP Phone</label>
                        <p>{preEmployment.emphistestdphone}</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>SAP Address</label>
                        <p>{preEmployment.emphistestdaddress}</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <br />
              <hr />
              <br />
              <div className="container ">
                <div className="row">
                  <h3 style={{ display: "inline" }}>Other Informations</h3>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    className="btn btn-primary"
                    onClick={() => setPageNo(9)}
                  >
                    Edit
                  </button>
                </div>
                <br />
                <div className="row">
                  <div className="col-md-5">
                    <div className="form-group">
                      <label>
                        Are You Currently Working for Another Employer?
                      </label>
                      <p>{otherInformation.eho_anotheremployee}</p>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <label>
                        Do You Intend to Work for Another Employer While
                        Employed With This Company?
                      </label>
                      <p>{otherInformation.eho_entended_work}</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Have you permit licence suspended or revoke?
                          </label>
                          <p>{otherInformation.tk_suspended_revoke}</p>
                        </div>
                      </div>
                      {otherInformation.tk_suspended_revoke === "Yes" ? (
                        <div className="col-md-12" id="step3revoke">
                          <div className="form-group">
                            <label>You must provide written statement </label>
                            <p>{otherInformation.tk_written_statment}</p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label>
                            Have you denied permit or previleage to motor
                            vehcile?
                          </label>
                          <p>{otherInformation.tk_denied_permit}</p>
                        </div>
                      </div>
                      {otherInformation.tk_denied_permit === "Yes" ? (
                        <div className="col-md-12" id="step3deniedpermit">
                          <div className="form-group">
                            <label>You must provide written statement </label>
                            <p>{otherInformation.tk_denied_written_statment}</p>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <hr />
                    </div>
                    <div className="table-wrap">
                      <table className="table table-bordered workdays-table">
                        <thead>
                          {/* <div className="form-group">
                            <label htmlFor="not_applicable">
                              Not applicable
                            </label>
                            &nbsp;
                            <input
                              type="checkbox"
                              id="not_applicable"
                              checked={otherInformation.not_applicable}
                            />
                          </div> */}
                        </thead>
                        <tbody>
                          <tr>
                            <th>DAY</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                          </tr>
                          <tr>
                            <th>DATE</th>
                            <td>{otherInformation.dh_date7}</td>
                            <td>{otherInformation.dh_date6}</td>
                            <td>{otherInformation.dh_date5}</td>
                            <td>{otherInformation.dh_date4}</td>
                            <td>{otherInformation.dh_date3}</td>
                            <td>{otherInformation.dh_date2}</td>
                            <td>{otherInformation.dh_date1}</td>
                          </tr>
                          <tr>
                            <th>HOURS WORKED</th>
                            <td>{otherInformation.dh_val7}</td>
                            <td>{otherInformation.dh_val6}</td>
                            <td>{otherInformation.dh_val5}</td>
                            <td>{otherInformation.dh_val4}</td>
                            <td>{otherInformation.dh_val3}</td>
                            <td>{otherInformation.dh_val2}</td>
                            <td>{otherInformation.dh_val1}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="form-group">
                      <label>
                        Date and time last relived from duty for previous day:
                      </label>
                      {/* <p>{dateToLongStr(otherInformation.dfpd)}</p> */}
                      <p>{otherInformation.dfpd}</p>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div style={{ textAlign: "center" }}>
                <button className="btn btn-primary" onClick={finalSubmit}>
                  Confirm & Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StepButtons goBack={backPage} goNext={nextPage} nextAllowed={true} /> */}
    </>
  );
};

export default ReviewDetails;
