import React from "react";

const StepButtons = ({ goBack, goNext, nextAllowed, isAdding }) => {
  return isAdding ? (
    ""
  ) : (
    <div className="driver-footer">
      <div className="container">
        <button
          onClick={goBack}
          className="pull-left btn btn-outline-white previous"
        >
          Back
        </button>
        {nextAllowed ? (
          <button
            onClick={() => goNext(false)}
            className="pull-right btn btn-dark next"
          >
            Next
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default StepButtons;
