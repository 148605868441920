import React from "react";

const DriverAddressForm = ({ data, eventHandler }) => {
  return (
    <div className="addressForms" id="addressForm1">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              name="d_current_address"
              value={data.d_current_address}
              onChange={eventHandler}
              maxLength="200"
              required
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>City</label>
            <input
              className="form-control"
              type="text"
              name="d_current_city"
              value={data.d_current_city}
              onChange={eventHandler}
              required
              maxLength="100"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>State / Province</label>
            <select
              className="form-control"
              name="d_current_province"
              value={data.d_current_province}
              onChange={eventHandler}
              required
              aria-labelledby="label_136"
            >
              <option value="">Select State/Province</option>
              <option value="Alabama"> Alabama </option>
              <option value="Alaska"> Alaska </option>
              <option value="Arizona"> Arizona </option>
              <option value="Arkansas"> Arkansas </option>
              <option value="California"> California </option>
              <option value="Colorado"> Colorado </option>
              <option value="Connecticut"> Connecticut </option>
              <option value="Delaware"> Delaware </option>
              <option value="District of Columbia">District of Columbia</option>
              <option value="Florida"> Florida </option>
              <option value="Georgia"> Georgia </option>
              <option value="Hawaii"> Hawaii </option>
              <option value="Idaho"> Idaho </option>
              <option value="Illinois"> Illinois </option>
              <option value="Indiana"> Indiana </option>
              <option value="Iowa"> Iowa </option>
              <option value="Kansas"> Kansas </option>
              <option value="Kentucky"> Kentucky </option>
              <option value="Louisiana"> Louisiana </option>
              <option value="Maine"> Maine </option>
              <option value="Maryland"> Maryland </option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan"> Michigan </option>
              <option value="Minnesota"> Minnesota </option>
              <option value="Mississippi"> Mississippi </option>
              <option value="Missouri"> Missouri </option>
              <option value="Montana"> Montana </option>
              <option value="Nebraska"> Nebraska </option>
              <option value="Nevada"> Nevada </option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York"> New York </option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Ohio"> Ohio </option>
              <option value="Oklahoma"> Oklahoma </option>
              <option value="Oregon"> Oregon </option>
              <option value="Pennsylvania"> Pennsylvania </option>
              <option value="Puerto Rico"> Puerto Rico </option>
              <option value="Rhode Island"> Rhode Island </option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee"> Tennessee </option>
              <option value="Texas"> Texas </option>
              <option value="Utah"> Utah </option>
              <option value="Vermont"> Vermont </option>
              <option value="Virgin Islands">Virgin Islands</option>
              <option value="Virginia"> Virginia </option>
              <option value="Washington"> Washington </option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin"> Wisconsin </option>
              <option value="Wyoming"> Wyoming </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Postal / Zip Code</label>
            <input
              className="form-control"
              type="text"
              name="d_current_zip"
              value={data.d_current_zip}
              onChange={eventHandler}
              required
              minLength="5"
              maxLength="6"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Country</label>
            <select
              className="form-control"
              name="d_current_country"
              value={data.d_current_country}
              onChange={eventHandler}
              required
              data-component="country"
              autoComplete="country"
            >
              <option value="">Choose your country</option>
              <option value="United States">United States</option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Number of years</label>
            <input
              className="form-control"
              type="text"
              name="d_current_years"
              value={data.d_current_years}
              onChange={eventHandler}
              required
              maxLength="50"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverAddressForm;
