import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, Button, Page, setOptions } from "@mobiscroll/react";
setOptions({
  theme: "ios",
  themeVariant: "light",
});

const CustomDateTimePicker = ({
  name,
  data,
  setData,
  readOnly,
  required = false,
}) => {
  let inputAttrs = {
    placeholder: "MM-DD-YYYY HH:MM AM",
    className: "form-control",
  };
  if (required) inputAttrs.required = true;
  if (readOnly) inputAttrs.readOnly = true;
  return (
    <Datepicker
      controls={["date", "time"]}
      inputComponent="input"
      inputProps={inputAttrs}
      name={name}
      onChange={(e) => {
        if (e.valueText) setData({ ...data, [name]: e.valueText });
      }}
      value={data[name]}
    />
  );
};

export default CustomDateTimePicker;
