import React, { useEffect } from "react";
// import CusDatePicker from "../components/CusDatePicker";
import CustomDatePicker from "../components/CustomDatePicker";
import "jquery-mask-plugin/dist/jquery.mask.min.js";
import $ from "jquery";

const EmploymentHistoryForm = ({ data, eventHandler, setData }) => {
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    $(".usPhn").mask("000-000-0000");
  }, []);
  return (
    <div className="employmentForms" id="employmentForm0">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label>Company Name</label>
            <input
              className="form-control"
              type="text"
              name="eh_company_name"
              value={data.eh_company_name}
              onChange={eventHandler}
              maxLength="120"
              required
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Address</label>
            <input
              className="form-control"
              type="text"
              name="eh_current_address"
              value={data.eh_current_address}
              onChange={eventHandler}
              required
              maxLength="255"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>City</label>
            <input
              className="form-control"
              type="text"
              name="eh_city"
              value={data.eh_city}
              onChange={eventHandler}
              maxLength="100"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>State / Province</label>
            {/* <input
              className="form-control"
              type="text"
              name="eh_state"
              value={data.eh_state}
              onChange={eventHandler}
              maxLength="100"
              required
            /> */}
            <select
              className="form-control"
              name="eh_state"
              value={data.eh_state}
              onChange={eventHandler}
              required
              aria-labelledby="label_136"
            >
              <option value="">Select State/Province</option>
              <option value="Alabama"> Alabama </option>
              <option value="Alaska"> Alaska </option>
              <option value="Arizona"> Arizona </option>
              <option value="Arkansas"> Arkansas </option>
              <option value="California"> California </option>
              <option value="Colorado"> Colorado </option>
              <option value="Connecticut"> Connecticut </option>
              <option value="Delaware"> Delaware </option>
              <option value="District of Columbia">District of Columbia</option>
              <option value="Florida"> Florida </option>
              <option value="Georgia"> Georgia </option>
              <option value="Hawaii"> Hawaii </option>
              <option value="Idaho"> Idaho </option>
              <option value="Illinois"> Illinois </option>
              <option value="Indiana"> Indiana </option>
              <option value="Iowa"> Iowa </option>
              <option value="Kansas"> Kansas </option>
              <option value="Kentucky"> Kentucky </option>
              <option value="Louisiana"> Louisiana </option>
              <option value="Maine"> Maine </option>
              <option value="Maryland"> Maryland </option>
              <option value="Massachusetts">Massachusetts</option>
              <option value="Michigan"> Michigan </option>
              <option value="Minnesota"> Minnesota </option>
              <option value="Mississippi"> Mississippi </option>
              <option value="Missouri"> Missouri </option>
              <option value="Montana"> Montana </option>
              <option value="Nebraska"> Nebraska </option>
              <option value="Nevada"> Nevada </option>
              <option value="New Hampshire">New Hampshire</option>
              <option value="New Jersey">New Jersey</option>
              <option value="New Mexico">New Mexico</option>
              <option value="New York"> New York </option>
              <option value="North Carolina">North Carolina</option>
              <option value="North Dakota">North Dakota</option>
              <option value="Ohio"> Ohio </option>
              <option value="Oklahoma"> Oklahoma </option>
              <option value="Oregon"> Oregon </option>
              <option value="Pennsylvania"> Pennsylvania </option>
              <option value="Puerto Rico"> Puerto Rico </option>
              <option value="Rhode Island"> Rhode Island </option>
              <option value="South Carolina">South Carolina</option>
              <option value="South Dakota">South Dakota</option>
              <option value="Tennessee"> Tennessee </option>
              <option value="Texas"> Texas </option>
              <option value="Utah"> Utah </option>
              <option value="Vermont"> Vermont </option>
              <option value="Virgin Islands">Virgin Islands</option>
              <option value="Virginia"> Virginia </option>
              <option value="Washington"> Washington </option>
              <option value="West Virginia">West Virginia</option>
              <option value="Wisconsin"> Wisconsin </option>
              <option value="Wyoming"> Wyoming </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Postal / Zip Code</label>
            <input
              className="form-control"
              type="number"
              name="eh_zip"
              value={data.eh_zip}
              onChange={eventHandler}
              maxLength="6"
              minLength="5"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Country</label>
            <select
              className="form-control"
              required
              name="eh_country"
              value={data.eh_country}
              onChange={eventHandler}
              data-component="country"
              autocomplete="country"
            >
              <option value="United States" selected="selected">
                United States
              </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Contact Person</label>
            <input
              className="form-control"
              type="text"
              name="eh_company_cperson"
              value={data.eh_company_cperson}
              onChange={eventHandler}
              maxLength="80"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Mobile Number</label>
            <input
              className="form-control usPhn"
              type="text"
              name="eh_mobile_no"
              value={data.eh_mobile_no}
              onChange={eventHandler}
              required
              maxLength="13"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>FROM Date</label>
            {/* <input
              className="form-control dynamicdate jqdate"
              type="text"
              name="eh_from_date"
              value={data.eh_from_date}
              onChange={eventHandler}
              required
            /> */}
            {/* <CusDatePicker
              name="eh_from_date"
              data={data}
              setData={setData}
              required={true}
            /> */}
            <CustomDatePicker
              name="eh_from_date"
              data={data}
              setData={setData}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>To Date</label>
            {/* <input
              className="form-control dynamicdate jqdate"
              type="text"
              name="eh_to_date"
              value={data.eh_to_date}
              onChange={eventHandler}
              required
            /> */}
            {/* <CusDatePicker
              name="eh_to_date"
              data={data}
              setData={setData}
              required={true}
            /> */}
            <CustomDatePicker
              name="eh_to_date"
              data={data}
              setData={setData}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Position Held</label>
            <input
              className="form-control"
              type="text"
              name="eh_position_head"
              value={data.eh_position_head}
              onChange={eventHandler}
              maxLength="80"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Salary/Wage</label>
            <input
              className="form-control"
              type="text"
              name="eh_salary_wages"
              value={data.eh_salary_wages}
              onChange={eventHandler}
              maxLength="10"
              oninput="this.value = this.value.replace(/[^0-9.]g, ''); this.value = this.value.replace(/(..*).g, '$1');"
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>
              <div className="vg">
                &nbsp;
                <br />
                &nbsp;
              </div>
              Were You Subject to the Federal Motor Carrier Safety Regulations
              While Employed?
            </label>
            <select
              className="form-control"
              name="eho_regulation_employed"
              value={data.eho_regulation_employed}
              onChange={eventHandler}
              aria-labelledby="label_133"
            >
              <option value="">Blank</option>
              <option value="Yes"> Yes </option>
              <option value="No"> No </option>
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>
              Was Your Job Designated as a Safety-sensitive Function in Any
              Dot-regulated Mode (Commercial Driver) Subject to the Fmcsr,
              Parts40 and 382?
            </label>
            <select
              className="form-control"
              name="eho_commercial_driver"
              value={data.eho_commercial_driver}
              onChange={eventHandler}
              aria-labelledby="label_133"
            >
              <option value="">Blank</option>
              <option value="Yes"> Yes </option>
              <option value="No"> No </option>
            </select>
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label>Reason for Leaving</label>
            <textarea
              className="form-control"
              name="eh_reason_leaving"
              value={data.eh_reason_leaving}
              onChange={eventHandler}
              maxLength="200"
              style={{ height: "100px" }}
            ></textarea>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="sec-header">
            Explain any gaps in employment with reason?
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>From Date </label>
            {/* <input
              className="form-control employeegapdate jqdate"
              type="text"
              name="d_empgap_date"
              value={data.d_empgap_date}
              onChange={eventHandler}
              placeholder="Enter date"
            /> */}
            {/* <CusDatePicker name="d_empgap_date" data={data} setData={setData} /> */}
            <CustomDatePicker
              name="d_empgap_date"
              data={data}
              setData={setData}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>To Date </label>
            {/* <input
              className="form-control employeegapdate jqdate"
              type="text"
              name="d_empgap_date_end"
              value={data.d_empgap_date_end}
              onChange={eventHandler}
              placeholder="Enter date"
            /> */}
            {/* <CusDatePicker
              name="d_empgap_date_end"
              data={data}
              setData={setData}
            /> */}
            <CustomDatePicker
              name="d_empgap_date_end"
              data={data}
              setData={setData}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label> Reason for gap </label>
            <textarea
              className="form-control"
              name="eh_reason_empgap"
              value={data.eh_reason_empgap}
              onChange={eventHandler}
              maxLength="200"
              style={{ height: "100px" }}
              placeholder="Enter Reason"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmploymentHistoryForm;
