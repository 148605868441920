import React, { useState, useEffect } from "react";
import "jquery-validation";
import $ from "jquery";
import { postToServer } from "../apis";
import StepButtons from "../components/StepButtons";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { dateToLongStr } from "../common";
import GoToReviewPageButton from "../components/GoToReviewPageButton";
import CustomDateTimePicker from "../components/CustomDateTimePicker";

const OtherInformation = ({
  data,
  setData,
  backPage,
  nextPage,
  credentials,
  isDone,
  lastPage,
}) => {
  // ---------- functions ----------
  const eventHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const notApplicableHandler = (e) => {
    let tempData = {};
    if (e.target.checked) {
      for (let i = 0; i < 7; i++) tempData["dh_val" + (i + 1)] = 0;
    } else {
      for (let i = 0; i < 7; i++) tempData["dh_val" + (i + 1)] = "";
    }
    setData({ ...data, ...tempData, not_applicable: e.target.checked });
  };
  const goNext = async (goLast) => {
    // console.log(JSON.stringify({ ...credentials, ...data }));
    const form = $("#data-form");
    form.validate();
    if (form.valid()) {
      const result = await postToServer("/otherInfo", {
        ...credentials,
        ...data,
        // dfpd: dateToLongStr(data.dfpd),
      });
      if (result.status) {
        goLast ? lastPage() : nextPage();
      }
    }
  };
  const addDaysToDate = (days) => {
    var res = new Date();
    res.setDate(res.getDate() + days);
    // return res.toLocaleDateString("en-CA");
    return res.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  };
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    let tempData = {};
    for (let i = 0; i < 7; i++) {
      tempData["dh_date" + (i + 1)] = addDaysToDate(-i);
    }
    setData({ ...data, ...tempData });
    // $(".react-datepicker__input-container input").attr("readonly", true);
  }, []);
  // ---------- rendering UI ----------
  return (
    <>
      <div className="general-content otherInfo">
        <div className="row d-flex">
          <div className="col-md-12">
            <form id="data-form">
              <div className="driver-form-container">
                <div className="container-fluid px-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="dr-head clearfix">
                        <div className="pull-left">
                          Hours worked in the last 7 days starting with today
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container px-30">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form-group">
                        <label>
                          Are You Currently Working for Another Employer?
                        </label>
                        <select
                          className="form-control"
                          name="eho_anotheremployee"
                          value={data.eho_anotheremployee}
                          onChange={eventHandler}
                          aria-labelledby="label_133"
                        >
                          <option value="">Select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="form-group">
                        <label>
                          Do You Intend to Work for Another Employer While
                          Employed With This Company?
                        </label>
                        <select
                          className="form-control"
                          name="eho_entended_work"
                          value={data.eho_entended_work}
                          onChange={eventHandler}
                          aria-labelledby="label_133"
                        >
                          <option value="">Select an option</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Have you permit licence suspended or revoke?
                            </label>
                            <select
                              className="form-control"
                              name="tk_suspended_revoke"
                              value={data.tk_suspended_revoke}
                              onChange={eventHandler}
                              aria-labelledby="label_133"
                              required
                            >
                              <option value="">Select an option</option>
                              <option value="Yes">Yes</option>
                              <option value="No" selected="">
                                No
                              </option>
                            </select>
                          </div>
                        </div>
                        {data.tk_suspended_revoke === "Yes" ? (
                          <div className="col-md-12" id="step3revoke">
                            <div className="form-group">
                              <label>You must provide written statement </label>
                              <textarea
                                className="form-control"
                                name="tk_written_statment"
                                value={data.tk_written_statment}
                                onChange={eventHandler}
                                required
                              ></textarea>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Have you denied permit or previleage to motor
                              vehcile?
                            </label>
                            <select
                              className="form-control"
                              name="tk_denied_permit"
                              value={data.tk_denied_permit}
                              onChange={eventHandler}
                              aria-labelledby="label_133"
                              required
                            >
                              <option value="">Select an option</option>
                              <option value="Yes">Yes</option>
                              <option value="No" selected="">
                                No
                              </option>
                            </select>
                          </div>
                        </div>
                        {data.tk_denied_permit === "Yes" ? (
                          <div className="col-md-12" id="step3deniedpermit">
                            <div className="form-group">
                              <label>You must provide written statement </label>
                              <textarea
                                className="form-control"
                                name="tk_denied_written_statment"
                                value={data.tk_denied_written_statment}
                                onChange={eventHandler}
                                required
                              ></textarea>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-wrap">
                        <table className="table table-bordered workdays-table">
                          <thead>
                            <div className="form-group">
                              <label htmlFor="not_applicable">
                                Not applicable
                              </label>
                              &nbsp;
                              <input
                                type="checkbox"
                                onChange={notApplicableHandler}
                                id="not_applicable"
                                checked={data.not_applicable}
                              />
                            </div>
                          </thead>
                          <tbody>
                            <tr>
                              <th>DAY</th>
                              <th>1</th>
                              <th>2</th>
                              <th>3</th>
                              <th>4</th>
                              <th>5</th>
                              <th>6</th>
                              <th>7</th>
                            </tr>
                            <tr>
                              <th>DATE</th>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date7"
                                  value={data.dh_date7}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date6"
                                  value={data.dh_date6}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date5"
                                  value={data.dh_date5}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date4"
                                  value={data.dh_date4}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date3"
                                  value={data.dh_date3}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date2"
                                  value={data.dh_date2}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>

                              <td>
                                <input
                                  type="text"
                                  name="dh_date1"
                                  value={data.dh_date1}
                                  onChange={eventHandler}
                                  aria-invalid="false"
                                  className="valid form-control dh_date7"
                                />
                              </td>
                            </tr>
                            <tr className="hours-row">
                              <th>HOURS WORKED</th>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val7"
                                  value={data.dh_val7}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val6"
                                  value={data.dh_val6}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val5"
                                  value={data.dh_val5}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val4"
                                  value={data.dh_val4}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val3"
                                  value={data.dh_val3}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val2"
                                  value={data.dh_val2}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                              <td>
                                <input
                                  className="form-control whdates"
                                  type="number"
                                  name="dh_val1"
                                  value={data.dh_val1}
                                  onChange={eventHandler}
                                  style={{ width: "100%" }}
                                  maxLength="2"
                                  required
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="col-md-7">
                      <div className="form-group">
                        <label>
                          Date and time last relived from duty for previous day:
                        </label>

                        {/* <input
                          className="form-control dynamicdate"
                          type="datetime-local"
                          name="dfpd"
                          value={data.dfpd}
                          onChange={eventHandler}
                        /> */}
                        {/* <DatePicker
                          selected={data.dfpd}
                          className="form-control"
                          placeholderText="MM-DD-YYYY HH:MM AM"
                          dateFormat="MM-dd-yyyy hh:mm aa"
                          showTimeSelect
                          onChange={(date) => setData({ ...data, dfpd: date })}
                        /> */}
                        <CustomDateTimePicker
                          name="dfpd"
                          data={data}
                          setData={setData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <StepButtons goBack={backPage} goNext={goNext} nextAllowed={true} />
      <GoToReviewPageButton update={goNext} isDone={isDone} />
    </>
  );
};

export default OtherInformation;
