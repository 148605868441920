import React from "react";

const AddMore = ({
  addAllowed,
  setIsAdding,
  pageName,
  addGuideLines,
  count,
}) => {
  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="data-expand text-center">
            {addAllowed ? (
              <div className="w-100">
                <button
                  onClick={() => setIsAdding(true)}
                  className="expand-link"
                  style={{ border: "none" }}
                >
                  Add {count !== 0 ? "Another" : ""} {pageName}
                </button>
              </div>
            ) : (
              ""
            )}
            <p>{addGuideLines}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMore;
