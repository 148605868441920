import React, { useState } from "react";
import $ from "jquery";
import Swal from "sweetalert2";
const LoginPage = (props) => {
  // ---------- states variables ----------
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  // ---------- functions ----------
  const eventHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const submit = () => {
    const form = $("#data-form");
    form.validate();
    if (form.valid()) {
      if (data.username === "dot_tab" && data.password === "9n+pR=7ZqDt`4)Lh") {
        props.setPageNo(0);
      } else {
        Swal.fire({
          icon: "error",
          title: "Incorrect Username OR Password",
        });
      }
    }
  };
  // ---------- code runs onces when file loads ----------
  return (
    <>
      <div className="general-content driver-info">
        <div className="row d-flex">
          <div className="col-md-12">
            <div className="driver-form-container">
              <div className="container">
                <div className="col-md-12 text-center">
                  <h2 className="h2">LOGIN</h2>
                </div>
                <form id="data-form">
                  <div className="form-wrap">
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Username</label>
                        <input
                          className="form-control"
                          type="text"
                          name="username"
                          id="username"
                          value={data.username}
                          onChange={eventHandler}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label>Password</label>
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          value={data.password}
                          onChange={eventHandler}
                          required
                        />
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={submit}
                        >
                          Login
                        </button>
                      </div>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <StepButtons goBack={backPage} goNext={goNext} nextAllowed={true} />
      <GoToReviewPageButton update={goNext} isDone={isDone} /> */}
    </>
  );
};

export default LoginPage;
