import React, { useState } from "react";
import StepButtons from "../components/StepButtons";
import Swal from "sweetalert2";
import FormButtons from "../components/FormButtons";
import AddMore from "../components/AddMore";
import FormTitle from "../components/FormTitle";
import { postToServer } from "../apis";
import "jquery-validation";
import $ from "jquery";
import GoToReviewPageButton from "../components/GoToReviewPageButton";
const FormList = ({
  list,
  setList,
  data,
  setData,
  emptyObj,
  backPage,
  nextPage,
  FormComp,
  PanelComp,
  formTitle,
  addGuideLines,
  pageName,
  server_url,
  callLicenseDetailApi,
  alert = undefined,
  setAlert = undefined,
  credentials,
  specificPage = undefined,
  violations = undefined,
  emptyViolationObj = undefined,
  lastinsertmvrdataid = undefined,
  isDone,
  lastPage,
  mvrLoadeding = undefined,
  lastMvrReq = undefined,
}) => {
  // ---------- states variables ----------
  const [isAdding, setIsAdding] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  // ---------- functions ----------
  const saveData = () => {
    const form = $("#data-form");
    form.validate();
    if (form.valid()) {
      if (editIndex === null) {
        setList([...list, data]);
      } else {
        list[editIndex] = data;
        setEditIndex(null);
        setList([...list]);
      }
      setIsAdding(false);
      setData(emptyObj);
    }
  };
  const cancelData = () => {
    setData(emptyObj);
    setIsAdding(false);
    setEditIndex(null);
  };
  const eventHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const editData = (index) => {
    setEditIndex(index);
    setData(list[index]);
    setIsAdding(true);
  };
  const deleteData = (index) => {
    Swal.fire({
      title: "Do you really wants to delete this " + pageName + "?",
      icon: "warning",
      showCancelButton: true,
      buttons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        if (editIndex !== null) {
          setEditIndex(null);
          setData(emptyObj);
        }
        list.splice(index, 1);
        setList([...list]);
      }
    });
  };
  const goNext = async (goLast) => {
    // ---------- code for sending data on server ----------
    const reqData = { ...credentials, data: {} };
    if (specificPage == "violations") {
      reqData.lastinsertmvrdataid = lastinsertmvrdataid;
    }
    if (specificPage == "accidents") {
      reqData.data = { violations: {}, accidents: {} };
      for (const key in emptyViolationObj) {
        if (emptyViolationObj.hasOwnProperty(key)) {
          reqData.data.violations[key] = [];
        }
      }
      for (const key in emptyObj) {
        if (emptyObj.hasOwnProperty(key)) {
          reqData.data.accidents[key] = [];
        }
      }
      violations.forEach((element) => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            reqData.data.violations[key].push(element[key]);
          }
        }
      });
      list.forEach((element) => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            reqData.data.accidents[key].push(element[key]);
          }
        }
      });
    } else {
      for (const key in emptyObj) {
        if (emptyObj.hasOwnProperty(key)) {
          reqData.data[key] = [];
        }
      }
      list.forEach((element) => {
        for (const key in element) {
          if (element.hasOwnProperty(key)) {
            // console.log(key + " -> " + element[key]);
            reqData.data[key].push(element[key]);
          }
        }
      });
    }
    // console.log(JSON.stringify(reqData));
    if (server_url) {
      const result = await postToServer(server_url, reqData);
      if (result.status) {
        goLast ? lastPage() : nextPage();
      }
      // else console.log("==> got error while posteding data");
    }
    // ---------- ***************************** ----------
    if (setAlert) setAlert({ type: "info", msg: "", status: false });
  };
  const goBack = () => {
    if (setAlert) setAlert({ type: "info", msg: "", status: false });
    backPage();
  };

  // ---------- rendering UI ----------
  return (
    <>
      <div className="general-content licence">
        <div className="row d-flex">
          <div className="col-md-9">
            <div className="driver-form-container">
              <FormTitle
                title={formTitle}
                alert={alert}
                specificPage={specificPage}
              />
              <div className="container container-collapsed">
                {isAdding ? (
                  <form id="data-form">
                    <FormComp
                      data={data}
                      setData={setData}
                      eventHandler={eventHandler}
                      count={list.length}
                      editIndex={editIndex}
                    />
                  </form>
                ) : (
                  <AddMore
                    addAllowed={
                      specificPage === "violations" ||
                      specificPage === "accidents" ||
                      specificPage === "experiences" ||
                      specificPage === "employment_histories" ||
                      list.length < 3
                    }
                    setIsAdding={setIsAdding}
                    pageName={pageName}
                    addGuideLines={addGuideLines}
                    count={list.length}
                  />
                )}
              </div>
              <FormButtons
                cancelData={cancelData}
                saveData={saveData}
                isAdding={isAdding}
              />
            </div>
            {/* Footer for Driver Info Tab only */}
            {mvrLoadeding ? (
              ""
            ) : (
              <StepButtons
                goBack={goBack}
                goNext={goNext}
                nextAllowed={
                  list.length > 0 ||
                  (specificPage &&
                    (specificPage === "violations" ||
                      specificPage === "accidents"))
                }
                // nextAllowed={true}
                isAdding={isAdding}
              />
            )}
          </div>
          <div className="col-md-3 pl-md-0">
            <div className="licence-panel">
              <div className="title">{pageName}(s)</div>
              <PanelComp
                list={list}
                editData={editData}
                cancelData={cancelData}
                deleteData={deleteData}
                callLicenseDetailApi={callLicenseDetailApi}
                lastMvrReq={lastMvrReq}
              />
            </div>
          </div>
        </div>
      </div>
      {specificPage === "violations" ? (
        ""
      ) : (
        <GoToReviewPageButton update={goNext} isDone={isDone} />
      )}
    </>
  );
};

export default FormList;
