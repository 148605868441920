import React from "react";

const DriverExperiencePanel = ({ list, editData, deleteData }) => {
  return (
    <div id="license-panel">
      {list.length > 0 ? (
        list.map((data, index) => (
          <div
            id={"panel" + data.id}
            key={index}
            className="licence-list experience experience-panel-list"
          >
            <ul className="data ">
              <li className="clearfix">
                <div className="address">
                  <b>
                    {data.d_class_equipment}
                    <label>&nbsp;with&nbsp; </label>
                    {data.d_equipment_name}
                  </b>
                  <br />
                  <label>Entry Level Training: &nbsp; </label>
                  <b>{data.d_class_elt}</b>
                  <br />
                  <b>
                    {data.d_from}- {data.d_to}
                  </b>
                  <br />
                  <label>Appx. Miles: &nbsp;</label>
                  <b>{data.approx}</b>
                </div>
              </li>
              <li className="clearfix edit-data">
                <button
                  type="button"
                  style={{ border: "none" }}
                  className="edit experience-edit"
                  onClick={() => editData(index)}
                >
                  Edit
                </button>
                {index > 0 ? (
                  <button
                    type="button"
                    style={{
                      border: "none",
                      float: "right",
                      color: "red",
                    }}
                    className="edit delete experience-delete"
                    onClick={() => deleteData(index)}
                  >
                    Delete
                  </button>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ))
      ) : (
        <img src="images/empty-data.png" />
      )}
    </div>
  );
};

export default DriverExperiencePanel;
