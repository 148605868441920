import React from "react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";
import { Datepicker, Button, Page, setOptions } from "@mobiscroll/react";
setOptions({
  theme: "ios",
  themeVariant: "light",
});

const CustomDatePicker = ({
  name,
  data,
  setData,
  readOnly,
  required = false,
  callBack = undefined,
  min = undefined,
  max = undefined,
}) => {
  let inputAttrs = {
    placeholder: "MM/DD/YYYY",
    className: "form-control",
    name: name,
  };
  if (required) inputAttrs.required = true;
  if (readOnly) inputAttrs.disabled = true;
  return (
    <Datepicker
      controls={["date"]}
      inputComponent="input"
      inputProps={inputAttrs}
      // name={name}
      onChange={(e) => {
        if (e.valueText) setData({ ...data, [name]: e.valueText });
        if (callBack) callBack(name, e);
      }}
      value={data[name]}
      min={min}
      max={max}
    />
  );
};

export default CustomDatePicker;
