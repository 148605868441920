import React from "react";

const GoToReviewPageButton = (props) => {
  return (
    <>
      {props.isDone ? (
        <button
          class="btn btn-primary"
          style={{ position: "fixed", bottom: "20px", right: "20px" }}
          onClick={() => props.update(true)}
        >
          Go to Review Details Page
        </button>
      ) : (
        ""
      )}
    </>
  );
};

export default GoToReviewPageButton;
