import React from "react";

const FormTitle = (props) => {
  return (
    <div className="container-fluid px-30">
      <div className="row">
        <div className="col-md-12">
          <div className="dr-head clearfix">
            <div className="pull-left">{props.title}</div>
          </div>
          {props.specificPage === "violations" ? (
            <div
              className="pull-right"
              id="api-loader"
              style={{ display: "none" }}
            >
              <img src="images/loader.svg" alt="loading" />
            </div>
          ) : (
            ""
          )}
          {props.alert && props.alert.status ? (
            <div id="api-alert" className={"alert alert-" + props.alert.type}>
              {props.alert.msg}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default FormTitle;
