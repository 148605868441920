import React, { useEffect } from "react";

const DriverViolationPanel = ({
  list,
  editData,
  deleteData,
  callLicenseDetailApi,
  lastMvrReq,
}) => {
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    if (callLicenseDetailApi) {
      callLicenseDetailApi();
    }
  }, []);
  return (
    <div id="license-panel">
      {lastMvrReq.d_firstname !== "" ? (
        <p>
          {lastMvrReq.d_firstname} {lastMvrReq.d_lastanme} / #
          {lastMvrReq.dl_license} / {lastMvrReq.statename} /{" "}
          {lastMvrReq.d_dateofb}
        </p>
      ) : (
        ""
      )}
      {list.length > 0 ? (
        list.map((data, index) => (
          <div
            id={"panel" + data.id}
            key={index}
            className="licence-list violation-panel-list"
          >
            <ul className="data">
              <li className="clearfix">
                <label>Charge</label>
                <span className="value lic-no">{data.d_trfic_charge}</span>
              </li>
              <li className="clearfix">
                <div className="pull-left w-50">
                  <label>Date</label>
                  <span className="value">{data.d_trfic_todate}</span>
                </div>
                <div className="pull-right w-50 text-right">
                  <label>Penalty</label>
                  <span className="value">{data.d_trfic_penalty}</span>
                </div>
              </li>
              <li className="clearfix">
                <label>Location</label>
                <span className="value">{data.d_trfic_location}</span>
              </li>

              <li className="clearfix edit-data">
                <button
                  style={{ border: "none" }}
                  className="edit violation-edit"
                  onClick={() => editData(index)}
                >
                  Edit
                </button>
                {data.change_allowed ? (
                  <button
                    style={{
                      border: "none",
                      float: "right",
                      color: "red",
                    }}
                    className="edit delete violation-delete"
                    onClick={() => deleteData(index)}
                  >
                    Delete
                  </button>
                ) : (
                  ""
                )}
              </li>
            </ul>
          </div>
        ))
      ) : (
        <img src="images/empty-data.png" />
      )}
    </div>
  );
};

export default DriverViolationPanel;
