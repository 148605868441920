import React, { useState, useEffect } from "react";
import StepButtons from "../components/StepButtons";
import { postToServer } from "../apis";
import "jquery-mask-plugin/dist/jquery.mask.min.js";
import $ from "jquery";
// import CusDatePicker from "../components/CusDatePicker";
import Select from "react-select";
import GoToReviewPageButton from "../components/GoToReviewPageButton";
import CustomDatePicker from "../components/CustomDatePicker";

const DriverInfo = ({
  data,
  setData,
  backPage,
  nextPage,
  credentials,
  setCredentials,
  signedImage,
  companies,
  selectedCompany,
  setSelectedCompany,
  isDone,
  lastPage,
}) => {
  // ---------- functions ----------
  const eventHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const goNext = async (goLast) => {
    const form = $("#data-form");
    form.validate();
    if (form.valid()) {
      const credentialsChanged = credentials.duid && credentials.parent_id;
      const reqObj = credentialsChanged
        ? { ...data, ...credentials, signature_input: signedImage }
        : { ...data, signature_input: signedImage };
      // console.log(JSON.stringify(reqObj));
      const result = await postToServer("/driverInfo", reqObj);
      if (result.status && !credentialsChanged) {
        setCredentials({
          duid: result.data.duid,
          parent_id: result.data.parent_id,
        });
        goLast ? lastPage() : nextPage();
      } else if (result.status) {
        goLast ? lastPage() : nextPage();
      }
    }
  };
  const handleSearchSelect = (selectedObj) => {
    setData({ ...data, tk_company_uid: selectedObj.value });
    setSelectedCompany(selectedObj);
  };
  const filterOption = (option, inputValue) =>
    (option.label.toLowerCase().match(inputValue.toLowerCase()) || []).length >
    0;
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    $(".usPhn").mask("000-000-0000");
    $(".ssn").mask("000-00-0000");
    if (data.tk_company_uid === "" && companies.length) {
      setSelectedCompany(companies[0]);
      setData({ ...data, tk_company_uid: companies[0].value });
    }
  }, []);
  // ---------- rendering UI ----------
  return (
    <>
      <div className="general-content driver-info">
        <div className="row d-flex">
          <div className="col-md-12">
            <div className="driver-form-container">
              <div className="container">
                <form id="data-form" autoComplete="off">
                  <div className="form-wrap">
                    <div className="row">
                      <div className="col-md-4 company-name">
                        <div className="form-group">
                          <label>Choose Company Name</label>
                          {/* <select
                            className="form-control"
                            name="tk_company_uid"
                            id="company-select"
                            value={data.tk_company_uid}
                            onChange={eventHandler}
                            required
                          >
                            <option value="">Choose Company</option>
                            {companies.map((e, index) => (
                              <option key={index} value={e.value}>
                                {e.label}
                              </option>
                            ))}
                          </select> */}
                          <Select
                            options={companies}
                            value={selectedCompany}
                            onChange={handleSearchSelect}
                            required
                            filterOption={filterOption}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>First Name / Middle Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="d_first_name"
                            id="d_first_name"
                            value={data.d_first_name}
                            onChange={eventHandler}
                            maxLength="80"
                            required
                          />
                        </div>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="form-group">
                          <label>Middle Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="d_middle_name"
                            value={data.d_middle_name}
                            onChange={eventHandler}
                            maxLength="80"
                          />
                        </div>
                      </div> */}
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Last Name</label>
                          <input
                            className="form-control"
                            type="text"
                            name="d_last_name"
                            id="d_last_name"
                            value={data.d_last_name}
                            onChange={eventHandler}
                            maxLength="50"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Date of Birth</label>
                          {/* <input
                            className="form-control jqdate"
                            type="text"
                            name="d_date_of_birth"
                            id="d_date_of_birth"
                            value={data.d_date_of_birth}
                            onChange={eventHandler}
                            placeholder="MM/DD/YYYY"
                            required
                          /> */}
                          {/* <CusDatePicker
                            name="d_date_of_birth"
                            data={data}
                            setData={setData}
                            required={true}
                          /> */}
                          <CustomDatePicker
                            name="d_date_of_birth"
                            data={data}
                            setData={setData}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>E-mail</label>
                          <input
                            className="form-control"
                            type="email"
                            name="d_email"
                            value={data.d_email}
                            onChange={eventHandler}
                            maxLength="80"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Phone Number</label>
                          <input
                            className="form-control usPhn"
                            type="text"
                            name="d_phone_number"
                            value={data.d_phone_number}
                            onChange={eventHandler}
                            minLength="10"
                            maxLength="10"
                            required
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Social Security Number</label>
                          <input
                            className="form-control ssn"
                            type="text"
                            name="d_social_s_no"
                            value={data.d_social_s_no}
                            onChange={eventHandler}
                            maxLength="10"
                            required
                          />
                          <input
                            type="hidden"
                            name="lastinsertmvrdataid"
                            id="lastinsertmvrdataid"
                            value={data.lastinsertmvrdataid}
                            onChange={eventHandler}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <StepButtons goBack={backPage} goNext={goNext} nextAllowed={true} />
      <GoToReviewPageButton update={goNext} isDone={isDone} />
    </>
  );
};

export default DriverInfo;
