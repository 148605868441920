import React, { useState, useEffect } from "react";
import StepButtons from "../components/StepButtons";
import "jquery-validation";
import $ from "jquery";
import { postToServer } from "../apis";
import GoToReviewPageButton from "../components/GoToReviewPageButton";

const PreEmployment = ({
  data,
  setData,
  backPage,
  nextPage,
  credentials,
  isDone,
  lastPage,
  data2,
  setData2,
}) => {
  // ---------- functions ----------
  const eventHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const eventHandler2 = (e) => {
    setData2({ ...data2, [e.target.name]: e.target.value });
  };
  const goNext = async (goLast) => {
    // console.log(JSON.stringify({ ...credentials, ...data }));
    const form = $("#data-form");
    form.validate();
    if (form.valid()) {
      data.emphistestdaddress = `${data2.sap_address}, ${data2.sap_city}, ${data2.sap_state}, ${data2.sap_zip}`;
      const result = await postToServer("/employment2Info", {
        ...credentials,
        ...data,
      });
      if (result.status) {
        goLast ? lastPage() : nextPage();
      }
    }
  };
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    $(".usPhn").mask("000-000-0000");
  }, []);
  // ---------- rendering UI ----------
  return (
    <>
      <div className="general-content pre-employment">
        <div className="row d-flex">
          <div className="col-md-12">
            <form id="data-form">
              <div className="driver-form-container">
                <div className="container-fluid px-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="dr-head clearfix">
                        <div className="pull-left">
                          Previous pre-employment controlled substance and
                          alcohol testing
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  {/* className="container container-collapsed" */}
                  <div>
                    <div className="row">
                      <div className="col-md-12" style={{ display: "none" }}>
                        <div className="sec-header">
                          Previous pre-employment controlled substance and
                          alcohol testing
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tested Positive For Substance Abuse?</label>
                          <select
                            className="form-control"
                            name="testdpostvsubabs"
                            value={data.testdpostvsubabs}
                            onChange={eventHandler}
                            aria-labelledby="label_133"
                            required
                          >
                            <option value="">Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Refused A Substance Abuse Test?</label>
                          <select
                            className="form-control"
                            name="refusedsubtabtest"
                            value={data.refusedsubtabtest}
                            onChange={eventHandler}
                            aria-labelledby="label_133"
                            required
                          >
                            <option value="">Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Tested Positive For Alcohol Misuse?</label>
                          <select
                            className="form-control"
                            name="testdposvealchmisue"
                            value={data.testdposvealchmisue}
                            onChange={eventHandler}
                            aria-labelledby="label_133"
                            required
                          >
                            <option value="">Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Refused An Alcohal Misuse Test?</label>
                          <select
                            className="form-control"
                            name="refanalohlmisutest"
                            value={data.refanalohlmisutest}
                            onChange={eventHandler}
                            aria-labelledby="label_133"
                            required
                          >
                            <option value="">Select an option</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {data.testdpostvsubabs === "Yes" ||
                    data.refusedsubtabtest === "Yes" ||
                    data.testdposvealchmisue === "Yes" ||
                    data.refanalohlmisutest === "Yes" ? (
                      <>
                        <div className="row" id="sapRow">
                          <div className="col-md-12">
                            <div className="sec-header"></div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>SAP Name</label>
                              <input
                                className="form-control"
                                type="text"
                                name="emphistestdname"
                                value={data.emphistestdname}
                                onChange={eventHandler}
                                maxLength="100"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>SAP Phone</label>
                              <input
                                className="form-control emphistestphonevalid usPhn"
                                type="text"
                                name="emphistestdphone"
                                value={data.emphistestdphone}
                                onChange={eventHandler}
                                maxLength="12"
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <label>SAP Address</label>
                              <input
                                className="form-control"
                                type="text"
                                name="sap_address"
                                value={data2.sap_address}
                                onChange={eventHandler2}
                                maxLength="200"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>SAP City</label>
                              <input
                                className="form-control"
                                type="text"
                                name="sap_city"
                                value={data2.sap_city}
                                onChange={eventHandler2}
                                maxLength="200"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label>SAP State</label>
                              {/* <input
                                className="form-control"
                                type="text"
                                name="sap_state"
                                value={data2.sap_state}
                                onChange={eventHandler2}
                                maxLength="200"
                                required
                              /> */}
                              <select
                                className="form-control"
                                name="sap_state"
                                value={data2.sap_state}
                                onChange={eventHandler2}
                                required
                                aria-labelledby="label_136"
                              >
                                <option value="">Select State/Province</option>
                                <option value="Alabama"> Alabama </option>
                                <option value="Alaska"> Alaska </option>
                                <option value="Arizona"> Arizona </option>
                                <option value="Arkansas"> Arkansas </option>
                                <option value="California"> California </option>
                                <option value="Colorado"> Colorado </option>
                                <option value="Connecticut">
                                  {" "}
                                  Connecticut{" "}
                                </option>
                                <option value="Delaware"> Delaware </option>
                                <option value="District of Columbia">
                                  District of Columbia
                                </option>
                                <option value="Florida"> Florida </option>
                                <option value="Georgia"> Georgia </option>
                                <option value="Hawaii"> Hawaii </option>
                                <option value="Idaho"> Idaho </option>
                                <option value="Illinois"> Illinois </option>
                                <option value="Indiana"> Indiana </option>
                                <option value="Iowa"> Iowa </option>
                                <option value="Kansas"> Kansas </option>
                                <option value="Kentucky"> Kentucky </option>
                                <option value="Louisiana"> Louisiana </option>
                                <option value="Maine"> Maine </option>
                                <option value="Maryland"> Maryland </option>
                                <option value="Massachusetts">
                                  Massachusetts
                                </option>
                                <option value="Michigan"> Michigan </option>
                                <option value="Minnesota"> Minnesota </option>
                                <option value="Mississippi">
                                  {" "}
                                  Mississippi{" "}
                                </option>
                                <option value="Missouri"> Missouri </option>
                                <option value="Montana"> Montana </option>
                                <option value="Nebraska"> Nebraska </option>
                                <option value="Nevada"> Nevada </option>
                                <option value="New Hampshire">
                                  New Hampshire
                                </option>
                                <option value="New Jersey">New Jersey</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York"> New York </option>
                                <option value="North Carolina">
                                  North Carolina
                                </option>
                                <option value="North Dakota">
                                  North Dakota
                                </option>
                                <option value="Ohio"> Ohio </option>
                                <option value="Oklahoma"> Oklahoma </option>
                                <option value="Oregon"> Oregon </option>
                                <option value="Pennsylvania">
                                  {" "}
                                  Pennsylvania{" "}
                                </option>
                                <option value="Puerto Rico">
                                  {" "}
                                  Puerto Rico{" "}
                                </option>
                                <option value="Rhode Island">
                                  {" "}
                                  Rhode Island{" "}
                                </option>
                                <option value="South Carolina">
                                  South Carolina
                                </option>
                                <option value="South Dakota">
                                  South Dakota
                                </option>
                                <option value="Tennessee"> Tennessee </option>
                                <option value="Texas"> Texas </option>
                                <option value="Utah"> Utah </option>
                                <option value="Vermont"> Vermont </option>
                                <option value="Virgin Islands">
                                  Virgin Islands
                                </option>
                                <option value="Virginia"> Virginia </option>
                                <option value="Washington"> Washington </option>
                                <option value="West Virginia">
                                  West Virginia
                                </option>
                                <option value="Wisconsin"> Wisconsin </option>
                                <option value="Wyoming"> Wyoming </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <label>SAP Zip</label>
                              <input
                                className="form-control"
                                type="text"
                                name="sap_zip"
                                value={data2.sap_zip}
                                onChange={eventHandler2}
                                maxLength="6"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="container-fluid px-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="dr-foot clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <StepButtons goBack={backPage} goNext={goNext} nextAllowed={true} />
      <GoToReviewPageButton update={goNext} isDone={isDone} />
    </>
  );
};

export default PreEmployment;
