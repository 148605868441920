import React, { useState, useEffect } from "react";
// import CusDatePicker from "../components/CusDatePicker";
import "jquery-validation";
import $ from "jquery";
import Select from "react-select";
import CustomDatePicker from "../components/CustomDatePicker";

const options = [
  { value: "Van", label: "Van" },
  { value: "Tank", label: "Tank" },
  { value: "Refer", label: "Refer" },
  { value: "Dump", label: "Dump" },
  { value: "Flat", label: "Flat" },
];

const DriverExperienceForm = ({
  data,
  eventHandler,
  setData,
  count,
  editIndex,
}) => {
  // ---------- states variables ----------
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [minFromDt, setMinFromDt] = useState("");
  const [maxFromDt, setMaxFromDt] = useState("");
  const [minToDt, setMinToDt] = useState("");
  const [maxToDt, setMaxToDt] = useState("");
  // ---------- functions ----------
  const handleSelect2 = (value) => {
    let vals = [];
    value.forEach((element) => {
      vals.push(element.value);
    });
    setData({ ...data, d_equipment_name: vals.toString() });
    setSelectedOptions(value);
    if (vals.length == 0) {
      $(".css-6j8wv5-Input input").attr("required", true);
    } else {
      $(".css-6j8wv5-Input input").removeAttr("required", true);
    }
  };
  const calcMiles = (name, e) => {
    let d1 = null;
    let d2 = null;
    if (name == "d_from") {
      d1 = e.valueText;
      d2 = data.d_to;
      setMinToDt(e.valueText);
    } else if (name == "d_to") {
      d1 = data.d_from;
      d2 = e.valueText;
      setMaxFromDt(e.valueText);
    }
    if (d1 && d2) {
      const arr1 = d1.split("/");
      const arr2 = d2.split("/");
      let months = 0;
      months += (arr2[2] - arr1[2]) * 12;
      months += arr2[0] - arr1[0];
      console.log("-------------");
      const daysDiff = arr2[1] - arr1[1];
      if (daysDiff >= 14) months++;
      else if (daysDiff < 0) months--;
      setData({
        ...data,
        approx: months < 0 ? 0 : months * 10000,
        [name]: e.valueText,
      });
    }
  };
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    if (data.d_equipment_name) {
      const tempData = data.d_equipment_name.split(",");
      let vals = [];
      tempData.forEach((element) => {
        vals.push({ value: element, label: element });
      });
      setSelectedOptions(vals);
      if (vals.length == 0) {
        $(".css-6j8wv5-Input input").attr("required", true);
      } else {
        $(".css-6j8wv5-Input input").removeAttr("required", true);
      }
    } else {
      $(".css-6j8wv5-Input input").attr("required", true);
    }
  }, []);

  return (
    <div className="experienceForms" id="experienceForm0">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Type of Trucks/Tractors</label>
            <select
              className="form-control"
              name="d_class_equipment"
              value={data.d_class_equipment}
              onChange={eventHandler}
              required
              aria-labelledby="label_136"
            >
              <option value="">Select an option</option>
              <option value="Straight Truck">Straight Truck</option>
              <option value="SEMI-TRAILER">TRACTOR/SEMI-TRAILER</option>
              <option value="2 TRAILERS">TRACTOR/2 TRAILERS</option>
              <option value="3 TRAILERS">TRACTOR/3 TRAILERS</option>
              <option value="SCHOOL BUS"> BUS/SCHOOL BUS </option>
            </select>
          </div>
        </div>
        {count === 0 || editIndex === 0 ? (
          <div className="col-md-6">
            <div className="form-group">
              <label>Less than one year driving experience</label>
              <select
                className="form-control"
                name="d_class_elt"
                value={data.d_class_elt}
                onChange={eventHandler}
                required
                aria-labelledby="label_136"
              >
                <option value="">Select one</option>
                <option value="Yes"> Yes </option>
                <option value="No"> No </option>
              </select>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-md-6">
          <div className="form-group" id="equipment_name">
            <label>Type of Attachment</label>
            <Select
              options={options}
              isMulti
              onChange={handleSelect2}
              value={selectedOptions}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Date you started driving</label>
            {/* <input
              className="form-control qualificationsdate jqdate"
              type="text"
              name="d_from"
              value={data.d_from}
              onChange={eventHandler}
              required
              maxLength="40"
            /> */}
            {/* <CusDatePicker
              name="d_from"
              data={data}
              setData={setData}
              required={true}
            /> */}
            <CustomDatePicker
              name="d_from"
              data={data}
              setData={setData}
              required={true}
              callBack={calcMiles}
              min={minFromDt}
              max={maxFromDt}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Date To</label>
            {/* <input
              className="form-control qualificationsdate jqdate"
              type="text"
              name="d_to"
              value={data.d_to}
              onChange={eventHandler}
              required
              maxLength="40"
            /> */}
            {/* <CusDatePicker
              name="d_to"
              data={data}
              setData={setData}
              required={true}
            /> */}
            <CustomDatePicker
              name="d_to"
              data={data}
              setData={setData}
              required={true}
              callBack={calcMiles}
              min={minToDt}
              max={maxToDt}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Approx. # of Miles</label>
            <input
              className="form-control"
              type="text"
              name="approx"
              value={data.approx}
              onChange={eventHandler}
              maxLength="50"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverExperienceForm;
