import React, { useEffect } from "react";
// import CusDatePicker from "../components/CusDatePicker";
import CustomDatePicker from "../components/CustomDatePicker";

const DriverViolationForm = ({ data, eventHandler, setData }) => {
  return (
    <div className="violationForms" id="violationForm0">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Date</label>
            {/* <input
              className="form-control dynamicdate jqdate"
              type="text"
              name="d_trfic_todate"
              value={data.d_trfic_todate}
              onChange={eventHandler}
              required
              maxLength="40"
            /> */}
            {/* <CusDatePicker
              name="d_trfic_todate"
              data={data}
              setData={setData}
              readOnly={!data.change_allowed}
              required={true}
            /> */}
            <CustomDatePicker
              name="d_trfic_todate"
              data={data}
              setData={setData}
              readOnly={!data.change_allowed}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Location</label>
            <input
              className="form-control"
              type="text"
              name="d_trfic_location"
              value={data.d_trfic_location}
              onChange={eventHandler}
              required
              maxLength="50"
              disabled={!data.change_allowed}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Charge</label>
            <input
              className="form-control"
              type="text"
              name="d_trfic_charge"
              value={data.d_trfic_charge}
              onChange={eventHandler}
              required
              maxLength="50"
              disabled={!data.change_allowed}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Penalty</label>
            <input
              className="form-control"
              type="text"
              name="d_trfic_penalty"
              value={data.d_trfic_penalty}
              onChange={eventHandler}
              required
              maxLength="50"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverViolationForm;
