import React, { useEffect } from "react";
// import CusDatePicker from "../components/CusDatePicker";
import CustomDatePicker from "../components/CustomDatePicker";

const DriverAccidentForm = ({ data, eventHandler, setData }) => {
  return (
    <div className="accidentForms" id="accidentForm0">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Date</label>
            {/* <input
              className="form-control dynamicdate jqdate"
              type="text"
              name="d_accident_todate"
              value={data.d_accident_todate}
              onChange={eventHandler}
              required
              maxLength="40"
            /> */}
            {/* <CusDatePicker
              name="d_accident_todate"
              data={data}
              setData={setData}
              readOnly={!data.change_allowed}
              required={true}
            /> */}
            <CustomDatePicker
              name="d_accident_todate"
              data={data}
              setData={setData}
              readOnly={!data.change_allowed}
              required={true}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Nature of Accident</label>
            <input
              className="form-control"
              type="text"
              name="d_accident_nature"
              value={data.d_accident_nature}
              onChange={eventHandler}
              maxLength="50"
              required
              disabled={!data.change_allowed}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label>Injury</label>
            <input
              className="form-control"
              type="text"
              name="d_accident_injury"
              value={data.d_accident_injury}
              onChange={eventHandler}
              placeholder="if any please mention else write NA"
              required
              maxLength="50"
              disabled={!data.change_allowed}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label>Fatality</label>
            <input
              className="form-control"
              type="text"
              name="d_accident_fatality"
              value={data.d_accident_fatality}
              onChange={eventHandler}
              placeholder="if any please mention else write NA"
              required
              maxLength="50"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriverAccidentForm;
