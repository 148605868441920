import "./App.css";
import { useState, useEffect, useRef } from "react";
import AppHeader from "./components/AppHeader";
import { licenseDetailApi, getCompanies } from "./apis";
import Swal from "sweetalert2";
// --------------- pages ---------------
import HomePage from "./pages/HomePage";
import DriverInfo from "./pages/DriverInfo";
import PreEmployment from "./pages/PreEmployment";
import FormList from "./pages/FormList";
import OtherInformation from "./pages/OtherInformation";
import LoginPage from "./pages/LoginPage";
// --------------- forms ---------------
import DriverLicenceForm from "./forms/DriverLicenceForm";
import DriverAddressForm from "./forms/DriverAddressForm";
import DriverViolationForm from "./forms/DriverViolationForm";
import DriverAccidentForm from "./forms/DriverAccidentForm";
import DriverExperienceForm from "./forms/DriverExperienceForm";
import EmploymentHistoryForm from "./forms/EmploymentHistoryForm";
// --------------- panels ---------------
import DriverLicencePanel from "./panels/DriverLicencePanel";
import DriverAddressPanel from "./panels/DriverAddressPanel";
import DriverViolationPanel from "./panels/DriverViolationPanel";
import DriverAccidentPanel from "./panels/DriverAccidentPanel";
import DriverExperiencePanel from "./panels/DriverExperiencePanel";
import EmploymentHistoryPanel from "./panels/EmploymentHistoryPanel";
import ReviewDetails from "./pages/ReviewDetails";
import ThanksPage from "./pages/ThanksPage";

function App() {
  // ---------- constants ----------
  const [lastMvrReq, setLastMvrReq] = useState({
    statename: "",
    dl_license: "",
    d_firstname: "",
    d_lastanme: "",
    d_dateofb: "",
    // UniqID: credentials.duid,
    UniqID: "",
  });
  const emptyLicenceObj = {
    d_license_number: "",
    d_license_exp_date: "",
    d_license_type: "",
    d_state_issue: "",
  };
  const emptyAddressObj = {
    d_current_address: "",
    d_current_city: "",
    d_current_province: "",
    d_current_zip: "",
    d_current_country: "United States",
    d_current_years: "",
  };
  const emptyViolationObj = {
    d_trfic_todate: "",
    d_trfic_location: "",
    d_trfic_charge: "",
    d_trfic_penalty: "",
    change_allowed: true,
  };
  const emptyAccidentObj = {
    d_accident_todate: "",
    d_accident_nature: "",
    d_accident_injury: "",
    d_accident_fatality: "",
    change_allowed: true,
    d_accident_location: "",
  };
  const emptyExperienceObj = {
    d_class_equipment: "",
    d_class_elt: "",
    d_equipment_name: "",
    d_from: "",
    d_to: "",
    approx: "",
  };
  const emptyEmploymentHistoryObj = {
    eh_company_name: "",
    eh_current_address: "",
    eh_city: "",
    eh_state: "",
    eh_zip: "",
    eh_country: "",
    eh_company_cperson: "",
    eh_mobile_no: "",
    eh_from_date: "",
    eh_to_date: "",
    eh_position_head: "",
    eh_salary_wages: "",
    eho_regulation_employed: "",
    eho_commercial_driver: "",
    eh_reason_leaving: "",
    d_empgap_date: "",
    d_empgap_date_end: "",
    eh_reason_empgap: "",
  };
  // ---------- states variables ----------
  const [data2, setData2] = useState({
    sap_address: "",
    sap_city: "",
    sap_state: "",
    sap_zip: "",
  });
  const [credentials, setCredentials] = useState({
    duid: null,
    parent_id: null,
  });
  const [selectedCompany, setSelectedCompany] = useState({});
  const [pageNo, setPageNo] = useState(null);
  const [signedImage, setSignedImage] = useState("");
  const [isDone, setIsDone] = useState(false);
  const [mvrLoaded, setMvrLoaded] = useState(false);
  const [mvrLoadeding, setMvrLoadeding] = useState(false);
  const [termConAccepted, setTermConAccepted] = useState(false);
  const [lastinsertmvrdataid, setLastinsertmvrdataid] = useState("");
  const [alert, setAlert] = useState({ type: "info", msg: "", status: false });
  const [companies, setCompanies] = useState([]);
  // data objects
  const [basicInfos, setBasicInfos] = useState({
    tk_company_uid: "",
    d_first_name: "",
    d_middle_name: "",
    d_last_name: "",
    d_date_of_birth: "",
    d_email: "",
    d_phone_number: "",
    d_social_s_no: "",
    // lastinsertmvrdataid: "",
  });
  const [licenceObj, setLicenceObj] = useState(emptyLicenceObj);
  const [addressObj, setAddressObj] = useState(emptyAddressObj);
  const [violationObj, setViolationObj] = useState(emptyViolationObj);
  const [accidentObj, setAccidentObj] = useState(emptyAccidentObj);
  const [experienceObj, setExperienceObj] = useState(emptyExperienceObj);
  const [employmentHistoryObj, setEmploymentHistoryObj] = useState(
    emptyEmploymentHistoryObj
  );
  const [preEmployment, setPreEmployment] = useState({
    testdpostvsubabs: "",
    refusedsubtabtest: "",
    testdposvealchmisue: "",
    refanalohlmisutest: "",
    emphistestdname: "",
    emphistestdphone: "",
    emphistestdaddress: "",
  });
  const [otherInformation, setOtherInformation] = useState({
    eho_anotheremployee: "",
    eho_entended_work: "",
    tk_suspended_revoke: "",
    tk_written_statment: "",
    tk_denied_permit: "",
    tk_denied_written_statment: "",
    dh_date7: "",
    dh_date6: "",
    dh_date5: "",
    dh_date4: "",
    dh_date3: "",
    dh_date2: "",
    dh_date1: "",
    dh_val7: "",
    dh_val6: "",
    dh_val5: "",
    dh_val4: "",
    dh_val3: "",
    dh_val2: "",
    dh_val1: "",
    dfpd: "",
    not_applicable: false,
  });
  // data object lists
  const [licenses, setLicenses] = useState([]);
  const [addresses, setAddresses] = useState([]);
  let [violations, setViolations] = useState([]);
  let [accidents, setAccidents] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [employmentHistories, setEmploymentHistories] = useState([]);
  // ---------- functions ----------
  const backPage = () => setPageNo(pageNo - 1);
  const nextPage = () => setPageNo(pageNo + 1);
  const lastPage = () => setPageNo(10);
  const callLicenseDetailApi = async () => {
    const reqData = {
      statename: licenses[0].d_state_issue,
      dl_license: licenses[0].d_license_number,
      d_firstname: basicInfos.d_first_name,
      d_lastanme: basicInfos.d_last_name,
      d_dateofb: basicInfos.d_date_of_birth,
      // UniqID: credentials.duid,
      UniqID: "",
    };
    if (
      //mvrLoaded &&
      reqData.statename === lastMvrReq.statename &&
      reqData.dl_license === lastMvrReq.dl_license &&
      reqData.d_firstname === lastMvrReq.d_firstname &&
      reqData.d_lastanme === lastMvrReq.d_lastanme &&
      reqData.d_dateofb === lastMvrReq.d_dateofb
    )
      return;
    setLastMvrReq(reqData);
    setMvrLoadeding(true);
    const loadingBox = document.getElementById("api-loader");
    loadingBox.style.display = "block";
    violations = violations.filter((violation) => violation.change_allowed);
    accidents = accidents.filter((accident) => accident.change_allowed);
    const result = await licenseDetailApi(reqData);
    setMvrLoaded(false);
    loadingBox.style.display = "none";
    console.log(result);
    // SOME ERROR (NO Record Found)
    if (result.status == 401) {
      // 401 means some error
      const api = result.record;
      const header = result.header;
      console.log(header);
      console.log(api);
      // show  sweet alert
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: result.error, // error message goes here
      });
      setAlert({
        type: "danger",
        msg: result.error,
        status: true,
      });
    }
    // RECORD FOUND

    if (result.status == 200) {
      setMvrLoaded(true);
      // 200 means record found
      const api = result.record; // full api response
      const header = result.header; // api communication response
      const record = api.DlRecord; // api dl record in it
      console.log(result.lastinsertmvrdataid);
      // $("#lastinsertmvrdataid").val(result.lastinsertmvrdataid);
      setLastinsertmvrdataid(lastinsertmvrdataid);

      // check if violations are there or not !!
      if (record.Result.Valid == "Y" && record.Result.IsClear == "Y") {
        setAlert({
          type: "info",
          msg: "No Violations Found !!",
          status: true,
        });
        setAccidents(accidents);
        setViolations(violations);
        setMvrLoadeding(false);
        return; // stop the script here only !!
      }

      setAlert({
        type: "info",
        msg: "Violations Found and Saved !!",
        status: true,
      });
      const mainItems = record.EventList.EventItem;
      console.log(mainItems);

      // this means only 1 violation is present
      if (!Array.isArray(mainItems)) {
        if (
          // this is for accident Records
          mainItems.Common.Subtype == "ACC" ||
          mainItems.Common.Subtype == "ACCD"
        ) {
          const data = {
            d_accident_todate: "",
            d_accident_nature: "",
            d_accident_injury: "",
            d_accident_fatality: "",
            change_allowed: false,
          };

          let acLocation = "";

          data.d_accident_todate =
            mainItems.Common.Date.Month +
            "/" +
            mainItems.Common.Date.Day +
            "/" +
            mainItems.Common.Date.Year;

          if (
            mainItems.hasOwnProperty("Accident") &&
            mainItems.Accident.hasOwnProperty("IsInjuryAccident")
          ) {
            data.d_accident_injury = mainItems.Accident.IsInjuryAccident;
          }
          if (
            mainItems.hasOwnProperty("Accident") &&
            mainItems.Accident.hasOwnProperty("IsFatalityAccident")
          ) {
            data.d_accident_fatality = mainItems.Accident.IsFatalityAccident;
          }
          if (mainItems.Common.hasOwnProperty("Location")) {
            // check if location is present or not
            acLocation = mainItems.Common.Location;
          } else {
            acLocation = mainItems.Common.State.Full;
          }

          if (!Array.isArray(mainItems.DescriptionList.DescriptionItem)) {
            data.d_accident_nature =
              mainItems.DescriptionList.DescriptionItem.StateDescription;
          } else {
            data.d_accident_nature =
              mainItems.DescriptionList.DescriptionItem[0].StateDescription;
          }
          accidents.push(data);
        } else {
          // this is for all other violations
          const data = {
            d_trfic_todate: "",
            d_trfic_location: "",
            d_trfic_charge: "",
            d_trfic_penalty: "",
            change_allowed: false,
          };
          // location
          if (mainItems.Common.hasOwnProperty("Location")) {
            // check if location is present or not
            data.d_trfic_location = mainItems.Common.Location;
          } else {
            data.d_trfic_location = mainItems.Common.State.Full;
          }
          // charge and penalty
          if (!Array.isArray(mainItems.DescriptionList.DescriptionItem)) {
            data.d_trfic_charge =
              mainItems.DescriptionList.DescriptionItem.StateDescription;
            if (
              mainItems.DescriptionList.DescriptionItem.hasOwnProperty(
                "StateAssignedPoints"
              )
            ) {
              data.d_trfic_penalty =
                mainItems.DescriptionList.DescriptionItem.StateAssignedPoints;
            }
          } else {
            data.d_trfic_charge =
              mainItems.DescriptionList.DescriptionItem[0].StateDescription;
            if (
              mainItems.DescriptionList.DescriptionItem[0].hasOwnProperty(
                "StateAssignedPoints"
              )
            ) {
              data.d_trfic_penalty =
                mainItems.DescriptionList.DescriptionItem[0].StateAssignedPoints;
            }
          }

          if (
            mainItems.hasOwnProperty("Violation") &&
            mainItems.Violation.hasOwnProperty("ConvictionDate")
          ) {
            data.d_trfic_todate =
              mainItems.Violation.ConvictionDate.Month +
              "/" +
              mainItems.Violation.ConvictionDate.Day +
              "/" +
              mainItems.Violation.ConvictionDate.Year;
          }
          // conviction date
          else if (mainItems.Common.hasOwnProperty("Date")) {
            // check if location is present or not
            data.d_trfic_todate =
              mainItems.Common.Date.Month +
              "/" +
              mainItems.Common.Date.Day +
              "/" +
              mainItems.Common.Date.Year;
          }
          violations.push(data);
        }
      }
      // This if multiple violations are there
      if (Array.isArray(mainItems)) {
        // for loop on every violation
        mainItems.forEach(handleViolations);

        function handleViolations(mainItem, index) {
          if (
            // this is for accident Records
            mainItem.Common.Subtype == "ACC" ||
            mainItem.Common.Subtype == "ACCD"
          ) {
            let acLocation = "";
            const data = {
              d_accident_todate: "",
              d_accident_nature: "",
              d_accident_injury: "",
              d_accident_fatality: "",
              change_allowed: false,
            };

            data.d_accident_todate =
              mainItem.Common.Date.Month +
              "/" +
              mainItem.Common.Date.Day +
              "/" +
              mainItem.Common.Date.Year;

            if (
              mainItem.hasOwnProperty("Accident") &&
              mainItem.Accident.hasOwnProperty("IsInjuryAccident")
            ) {
              data.d_accident_injury = mainItem.Accident.IsInjuryAccident;
            }
            if (
              mainItem.hasOwnProperty("Accident") &&
              mainItem.Accident.hasOwnProperty("IsFatalityAccident")
            ) {
              data.d_accident_fatality = mainItem.Accident.IsFatalityAccident;
            }

            if (!Array.isArray(mainItem.DescriptionList.DescriptionItem)) {
              data.d_accident_nature =
                mainItem.DescriptionList.DescriptionItem.StateDescription;
            } else {
              data.d_accident_nature =
                mainItem.DescriptionList.DescriptionItem[0].StateDescription;
            }
            if (mainItem.Common.hasOwnProperty("Location")) {
              // check if location is present or not
              acLocation = mainItem.Common.Location;
            } else {
              acLocation = mainItem.Common.State.Full;
            }
            accidents.push(data);
          } else {
            const data = {
              d_trfic_todate: "",
              d_trfic_location: "",
              d_trfic_charge: "",
              d_trfic_penalty: "",
              change_allowed: false,
            };

            // location
            if (mainItem.Common.hasOwnProperty("Location")) {
              // check if location is present or not
              data.d_trfic_location = mainItem.Common.Location;
            } else {
              data.d_trfic_location = mainItem.Common.State.Full;
            }
            // charge and penalty
            if (!Array.isArray(mainItem.DescriptionList.DescriptionItem)) {
              data.d_trfic_charge =
                mainItem.DescriptionList.DescriptionItem.StateDescription;
              if (
                mainItem.DescriptionList.DescriptionItem.hasOwnProperty(
                  "StateAssignedPoints"
                )
              ) {
                data.d_trfic_penalty =
                  mainItem.DescriptionList.DescriptionItem.StateAssignedPoints;
              }
            } else {
              data.d_trfic_charge =
                mainItem.DescriptionList.DescriptionItem[0].StateDescription;
              if (
                mainItem.DescriptionList.DescriptionItem[0].hasOwnProperty(
                  "StateAssignedPoints"
                )
              ) {
                data.d_trfic_penalty =
                  mainItem.DescriptionList.DescriptionItem[0].StateAssignedPoints;
              }
            }
            // conviction date
            if (
              mainItem.hasOwnProperty("Violation") &&
              mainItem.Violation.hasOwnProperty("ConvictionDate")
            ) {
              data.d_trfic_todate =
                mainItem.Violation.ConvictionDate.Month +
                "/" +
                mainItem.Violation.ConvictionDate.Day +
                "/" +
                mainItem.Violation.ConvictionDate.Year;
            }
            // conviction date
            else if (mainItem.Common.hasOwnProperty("Date")) {
              // check if location is present or not
              data.d_trfic_todate =
                mainItem.Common.Date.Month +
                "/" +
                mainItem.Common.Date.Day +
                "/" +
                mainItem.Common.Date.Year;
            }
            violations.push(data);
          }
        }
      }
    }
    setAccidents(accidents);
    setViolations(violations);
    setMvrLoadeding(false);
  };
  const updateCompanies = async () => {
    setCompanies(await getCompanies());
  };
  // ---------- code runs onces when file loads ----------
  useEffect(() => {
    updateCompanies();
  }, []);
  const renderPage = () => {
    switch (pageNo) {
      case 0:
        return (
          <HomePage
            nextPage={nextPage}
            signedImage={signedImage}
            setSignedImage={setSignedImage}
            termConAccepted={termConAccepted}
            setTermConAccepted={setTermConAccepted}
          />
        );
      case 1:
        return (
          <DriverInfo
            data={basicInfos}
            setData={setBasicInfos}
            backPage={backPage}
            nextPage={nextPage}
            credentials={credentials}
            setCredentials={setCredentials}
            signedImage={signedImage}
            companies={companies}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            isDone={isDone}
            lastPage={lastPage}
          />
        );
      case 2:
        return (
          <FormList
            list={licenses}
            setList={setLicenses}
            data={licenceObj}
            setData={setLicenceObj}
            emptyObj={emptyLicenceObj}
            backPage={backPage}
            nextPage={nextPage}
            FormComp={DriverLicenceForm}
            PanelComp={DriverLicencePanel}
            formTitle="All licenses held in the last 3 years starting with current license"
            pageName="License"
            server_url="/licenseInfo"
            credentials={credentials}
            isDone={isDone}
            lastPage={lastPage}
            addGuideLines="If you have more than one licenses, you can add upto 3 or continue to next steps."
          />
        );
      case 3:
        return (
          <FormList
            list={addresses}
            setList={setAddresses}
            data={addressObj}
            setData={setAddressObj}
            emptyObj={emptyAddressObj}
            backPage={backPage}
            nextPage={nextPage}
            FormComp={DriverAddressForm}
            PanelComp={DriverAddressPanel}
            formTitle="Addresses in the US you have live in for the past 3 years or as close to 3 years"
            addGuideLines="If you have more than one addresses, you can add upto 3 or continue to next steps."
            pageName="Address"
            credentials={credentials}
            server_url="/addressInfo"
            isDone={isDone}
            lastPage={lastPage}
          />
        );
      case 4:
        return (
          <FormList
            list={violations}
            setList={setViolations}
            data={violationObj}
            setData={setViolationObj}
            emptyObj={emptyViolationObj}
            backPage={backPage}
            nextPage={nextPage}
            FormComp={DriverViolationForm}
            PanelComp={DriverViolationPanel}
            formTitle="Record of Violations/Annual Review Certificate"
            addGuideLines="If you have more Violation(s), you can add more or continue to next step."
            pageName="Violation"
            callLicenseDetailApi={callLicenseDetailApi}
            pageNo={pageNo}
            alert={alert}
            setAlert={setAlert}
            credentials={credentials}
            specificPage="violations"
            lastinsertmvrdataid={lastinsertmvrdataid}
            server_url="/violationsInfo"
            isDone={isDone}
            lastPage={lastPage}
            mvrLoadeding={mvrLoadeding}
            lastMvrReq={lastMvrReq}
          />
        );
      case 5:
        return (
          <FormList
            list={accidents}
            setList={setAccidents}
            data={accidentObj}
            setData={setAccidentObj}
            emptyObj={emptyAccidentObj}
            backPage={backPage}
            nextPage={nextPage}
            FormComp={DriverAccidentForm}
            PanelComp={DriverAccidentPanel}
            formTitle="Record of Accidents"
            addGuideLines="If you have more Accident(s), you can add more or continue to next step."
            pageName="Accident"
            credentials={credentials}
            specificPage="accidents"
            violations={violations}
            emptyViolationObj={emptyViolationObj}
            server_url="/accidentsInfo"
            isDone={isDone}
            lastPage={lastPage}
          />
        );
      case 6:
        return (
          <FormList
            list={experiences}
            setList={setExperiences}
            data={experienceObj}
            setData={setExperienceObj}
            emptyObj={emptyExperienceObj}
            backPage={backPage}
            nextPage={nextPage}
            FormComp={DriverExperienceForm}
            PanelComp={DriverExperiencePanel}
            formTitle="Previous experience in truck driving"
            addGuideLines="If you have more Experience(s), you can add more or continue to next steps."
            pageName="Experience"
            credentials={credentials}
            server_url="/experienceInfo"
            isDone={isDone}
            lastPage={lastPage}
            specificPage="experiences"
          />
        );
      case 7:
        return (
          <FormList
            list={employmentHistories}
            setList={setEmploymentHistories}
            data={employmentHistoryObj}
            setData={setEmploymentHistoryObj}
            emptyObj={emptyEmploymentHistoryObj}
            backPage={backPage}
            nextPage={nextPage}
            FormComp={EmploymentHistoryForm}
            PanelComp={EmploymentHistoryPanel}
            formTitle="Employment History"
            addGuideLines="If you have more Employment History, you can add more or continue to next steps."
            pageName="Employment History"
            credentials={credentials}
            server_url="/employmentInfo"
            isDone={isDone}
            lastPage={lastPage}
            specificPage="employment_histories"
          />
        );
      case 8:
        return (
          <PreEmployment
            data={preEmployment}
            setData={setPreEmployment}
            backPage={backPage}
            nextPage={nextPage}
            credentials={credentials}
            isDone={isDone}
            lastPage={lastPage}
            data2={data2}
            setData2={setData2}
          />
        );
      case 9:
        return (
          <OtherInformation
            data={otherInformation}
            setData={setOtherInformation}
            backPage={backPage}
            nextPage={nextPage}
            credentials={credentials}
            isDone={isDone}
            lastPage={lastPage}
          />
        );
      case 10:
        return (
          <ReviewDetails
            setPageNo={setPageNo}
            basicInfos={basicInfos}
            setIsDone={setIsDone}
            licenses={licenses}
            addresses={addresses}
            violations={violations}
            accidents={accidents}
            experiences={experiences}
            employmentHistories={employmentHistories}
            preEmployment={preEmployment}
            otherInformation={otherInformation}
            credentials={credentials}
          />
        );
      case 11:
        return <ThanksPage />;
      default:
        return <LoginPage setPageNo={setPageNo} />;
    }
  };
  // ---------- rendering UI ----------
  return (
    <section className="driver-record tab-view">
      <AppHeader pageNo={pageNo} />
      <div className="general-container">{renderPage()}</div>
      <div className="full-page-loader" id="full-page-loader">
        <img src="images/loader.svg" alt="loading" />
      </div>
    </section>
  );
}

export default App;
