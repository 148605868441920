import React from "react";

const FormButtons = ({ cancelData, saveData, isAdding }) => {
  return isAdding ? (
    <div className="container-fluid px-30">
      <div className="row">
        <div className="col-md-12">
          <div className="dr-foot clearfix">
            <div className="pull-left">
              <button onClick={cancelData} className="remove-link">
                Cancel
              </button>
            </div>
            <div className="pull-right">
              <button onClick={saveData} className="add-link">
                Save
              </button>
            </div>
          </div>
          {/* <!--dr-foot--> */}
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default FormButtons;
